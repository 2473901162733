import { useEffect, useRef, useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import classNames from "classnames";
import { useGesture, useDrag } from "@use-gesture/react";
import { Box } from "@mui/material";
import { createText, createTextpath, createAggregateTextpath } from "./tapestryUtilities";
import { colors } from "../styles/colors";
import { hexToRgb, Color, Solver } from "../services/utilities";

const handleSize = 10;
const styles = {
  markupContainer: {
    pointerEvents: "none",
    position: "absolute",
    inset: 0,
  },
  markup: {
    position: "absolute",
    left: 0,
    top: 0,
    touchAction: "none",
    zIndex: 100,
    transformOrigin: "center",
  },
  markupImg: {
    boxSizing: "border-box",
    position: "absolute",
    left: 0,
    top: 0,
    touchAction: "none",
    height: "100%",
    width: "100%",
  },
  handle: {
    position: "absolute",
    width: handleSize + "px",
    height: handleSize + "px",
    background: "white",
    touchAction: "none",
    border: "2px solid " + colors.primaryGreen,
    cursor: "grab",
  },
  markupHitbox: {
    position: "absolute",
    inset: "-12px",
    touchAction: "none",
  },
};

const TapestryView = ({ svg, updatedPhrase, width, markupData, setMarkupData, colors, editingTapestry }) => {
  const [draggingMarkup, setDraggingMarkup] = useState(false);
  const tapestryContainer = useRef();
  const widthRef = useRef(width);
  const markupRefs = useRef([]);

  const setTapestryDimensions = useCallback(() => {
    const svgEl = tapestryContainer.current?.querySelector("svg.svg-container");
    if (svgEl) {
      const windowHeight = window.innerHeight - 84;
      const windowWidth = (window.innerWidth - (widthRef.current === 1 ? 55 : 65)) * widthRef.current;
      let svgWidth = windowWidth;
      let svgHeight = (svgEl.viewBox.baseVal.height * windowWidth) / svgEl.viewBox.baseVal.width;
      if (svgHeight > windowHeight) {
        svgHeight = windowHeight;
        svgWidth = (svgEl.viewBox.baseVal.width * windowHeight) / svgEl.viewBox.baseVal.height;
      }
      svgEl.style.width = svgWidth;
      svgEl.style.height = svgHeight;
      window.dispatchEvent(
        new CustomEvent("tapestryDimensions", {
          detail: { width: svgWidth, height: svgHeight },
        })
      );
    }
  }, []);

  useEffect(() => {
    widthRef.current = width;
    setTapestryDimensions();
  }, [setTapestryDimensions, width]);

  useEffect(() => {
    window.addEventListener("resize", setTapestryDimensions);
    return () => {
      window.removeEventListener("resize", setTapestryDimensions);
    };
  }, [setTapestryDimensions]);

  // initialize SVG
  useEffect(() => {
    if (svg && tapestryContainer.current) {
      const container = document.createElement("div");
      container.innerHTML = svg;
      const svgEl = container.querySelector("svg");
      tapestryContainer.current.appendChild(svgEl);

      // setup interactivity

      // phrase editing
      tapestryContainer.current?.querySelectorAll("[id^=phrase- i]").forEach((editable) => {
        const eventID = editable.id.slice(7);
        if (editable.tagName === "g") {
          editable.querySelectorAll("text").forEach((text) => {
            text.addEventListener("click", () =>
              window.dispatchEvent(
                new CustomEvent("showPhraseOptions", {
                  detail: { eventID },
                })
              )
            );
          });
        } else if (editable.tagName === "text") {
          editable.addEventListener("click", () =>
            window.dispatchEvent(
              new CustomEvent("showPhraseOptions", {
                detail: { eventID },
              })
            )
          );
        }
      });

      // phrase searching
      tapestryContainer.current
        ?.querySelectorAll(".slot-phrase-container, .symbol-phrase-container, text.viz-text tspan")
        .forEach((searchable) => {
          searchable.addEventListener("click", () =>
            window.dispatchEvent(
              new CustomEvent("selectText", {
                detail: { text: searchable.getAttribute("fulltext") },
              })
            )
          );
        });

      setTapestryDimensions();
    }
  }, [setTapestryDimensions, svg]);

  // update phrases on phrase selection
  useEffect(() => {
    const phraseContainer = tapestryContainer.current?.querySelector("#phrase-" + updatedPhrase?.eventID);
    if (phraseContainer) {
      if (updatedPhrase.selectedPhrase.bodyOptions) {
        // update text body
        const newText = createText({ ...updatedPhrase.selectedPhrase });
        const selectedPhraseContainer = d3.select(phraseContainer);
        selectedPhraseContainer.selectAll("tspan").remove();
        selectedPhraseContainer
          .selectAll("tspan")
          .data(newText.texts)
          .enter()
          .append("tspan")
          .text((d) => d.text)
          .attr("x", 0)
          .attr("dx", (d) => d.renderStyle.width / 2)
          .attr("dy", (d, i) => (i ? d.height : 0))
          .attr("fill", (d) => d.color)
          .on("click", () =>
            window.dispatchEvent(
              new CustomEvent("selectText", {
                detail: { text: updatedPhrase.selectedPhrase.textContent },
              })
            )
          );
      } else {
        // update textpath
        const parent = phraseContainer.parentNode;
        phraseContainer.remove();
        if (Array.isArray(updatedPhrase.selectedPhrase.textpathLength)) {
          createAggregateTextpath({ ...updatedPhrase.selectedPhrase, container: parent });
        } else {
          createTextpath({ ...updatedPhrase.selectedPhrase, container: parent });
        }
      }
    }
  }, [updatedPhrase]);

  // drag tapestry markups
  const draggingMarkupRef = useRef(false);
  const dragMarkup = useGesture({
    onDragStart: () => {
      setDraggingMarkup(true);
      draggingMarkupRef.current = true;
    },
    onDrag: ({ event, delta: [dx, dy], args: [i] }) => {
      event.preventDefault();
      if (draggingMarkupRef.current) {
        const tapScale = getTapestryDimensions().scale;
        const markupDataCopy = [...markupData];
        markupDataCopy[i].x += dx * tapScale;
        markupDataCopy[i].y += dy * tapScale;
        setMarkupData(markupDataCopy);
      }
    },
    onDragEnd: ({ args: [i] }) => {
      setDraggingMarkup(false);
      draggingMarkupRef.current = false;
      const tapDimensions = getTapestryDimensions();
      const viewBox = tapDimensions.viewBox;
      const markup = markupData[i];
      const markupDimensions = {
        width: markup.dimensions.width * markup.xScale,
        height: markup.dimensions.height * markup.yScale,
      };
      // if markup is not within the tapestry, delete it
      if (
        markup.x + markupDimensions.width < 0 ||
        markup.x + markupDimensions.width > viewBox.width ||
        markup.y + markupDimensions.height < 0 ||
        markup.y + markupDimensions.height > viewBox.height
      ) {
        const markupDataCopy = markupData.slice(0, i).concat(markupData.slice(i + 1));
        setMarkupData([...markupDataCopy]);
      }
    },
  });

  // drag transform handles
  const scaleSensitivity = 0.004;
  const scaleXMarkup = useDrag(({ event, delta: [dx, dy], args: [i] }) => {
    event.stopPropagation();
    const rotationAngle = markupData[i].rotate;
    // project onto local axis
    const deltaLocalX = dx * Math.cos(rotationAngle) + dy * Math.sin(rotationAngle);
    const markupDataCopy = [...markupData];
    markupDataCopy[i].xScale += deltaLocalX * scaleSensitivity;
    setMarkupData(markupDataCopy);
  });

  const scaleYMarkup = useDrag(({ event, delta: [dx, dy], args: [i] }) => {
    event.stopPropagation();
    const rotationAngle = markupData[i].rotate;
    // project onto local axis
    const deltaLocalY = dx * -Math.sin(rotationAngle) + dy * Math.cos(rotationAngle);
    const markupDataCopy = [...markupData];
    markupDataCopy[i].yScale -= deltaLocalY * scaleSensitivity;
    setMarkupData(markupDataCopy);
  });

  const rotateMarkup = useDrag(({ event, initial: [x0, y0], xy: [x, y], memo, args: [i] }) => {
    event.stopPropagation();

    if (!memo) {
      const markupBBox = markupRefs.current[i].getBoundingClientRect();
      const markupCenter = { x: 0, y: 0 };
      markupCenter.x = markupBBox.left + markupBBox.width / 2;
      markupCenter.y = markupBBox.top + markupBBox.height / 2;
      const initialAngle = Math.atan2(y0 - markupCenter.y, x0 - markupCenter.x);
      const rotationAngle = markupData[i].rotate;
      const initialRotation = rotationAngle;
      return { initialAngle, initialRotation, markupCenter };
    }

    const currentAngle = Math.atan2(y - memo.markupCenter.y, x - memo.markupCenter.x);
    const deltaAngle = currentAngle - memo.initialAngle;
    const initialRotation = memo.initialRotation;
    const markupDataCopy = [...markupData];
    markupDataCopy[i].rotate = initialRotation + deltaAngle;
    setMarkupData(markupDataCopy);
    return memo;
  });

  // filter css for color markups based on background colors
  const markupsFilter = useMemo(() => {
    const def = "none";
    if (!colors) return def;
    const rgb = hexToRgb(colors.primary);
    if (rgb.length !== 3) {
      return def;
    }

    const color = new Color(rgb[0], rgb[1], rgb[2]);
    const solver = new Solver(color);
    const result = solver.solve();

    return result.filter.slice(7, -1);
  }, [colors]);

  return (
    <Box sx={{ width, position: "relative" }} className="tapestry-container" ref={tapestryContainer}>
      <div style={{ ...styles.markupContainer, filter: markupsFilter }}>
        {markupData.map((markup, i) => {
          const tapScale = getTapestryDimensions().scale;
          if (!tapScale) return null;
          return (
            <div
              key={i}
              className={classNames("tap-markup", { dragging: draggingMarkup })}
              ref={(el) => (markupRefs.current[i] = el)}
              style={{
                ...styles.markup,
                pointerEvents: editingTapestry ? "all" : "none",
                height: markup.dimensions.height / tapScale,
                width: markup.dimensions.width / tapScale,
                transform: `translate(${markup.x / tapScale}px, ${markup.y / tapScale}px) rotate(${
                  markup.rotate
                }rad) scale(${markup.xScale}, ${markup.yScale})`,
              }}
              {...dragMarkup(i)}>
              <div style={styles.markupHitbox}></div>
              <img
                src={`https://vt-markups.s3.us-west-2.amazonaws.com/${markup.id}` + ".svg"} // for some reason my IDE breaks if I don't concat here 🤯
                style={styles.markupImg}
                draggable="false"
                alt="Tapestry Markup"
              />
              <div
                className="handle"
                {...scaleXMarkup(i)}
                style={{
                  ...styles.handle,
                  cursor: "col-resize",
                  right: 0,
                  top: "50%",
                  transform: `translate(50%, -50%) scale(${1 / markup.xScale}, ${1 / markup.yScale})`,
                }}></div>
              <div
                className="handle"
                {...scaleYMarkup(i)}
                style={{
                  ...styles.handle,
                  cursor: "row-resize",
                  right: "50%",
                  top: 0,
                  transform: `translate(50%, -50%) scale(${1 / markup.xScale}, ${1 / markup.yScale})`,
                }}></div>
              <div
                className="handle"
                {...rotateMarkup(i)}
                style={{
                  ...styles.handle,
                  borderRadius: "50%",
                  right: 0,
                  top: 0,
                  transform: `translate(50%, -50%) scale(${1 / markup.xScale}, ${1 / markup.yScale})`,
                }}></div>
            </div>
          );
        })}
      </div>
    </Box>
  );
};
TapestryView.propTypes = {
  svg: PropTypes.string,
  updatedPhrase: PropTypes.object,
  width: PropTypes.number,
  markupData: PropTypes.array,
  setMarkupData: PropTypes.func,
  editingTapestry: PropTypes.bool,
  colors: PropTypes.object,
};

export function getTapestryDimensions() {
  const tapestryEl = document.querySelector(".svg-container");
  const tapestryBBox = tapestryEl?.getBoundingClientRect();
  return {
    scale: tapestryEl?.viewBox.baseVal.height / tapestryBBox?.height,
    tapestryBBox,
    viewBox: tapestryEl?.viewBox.baseVal,
  };
}

export default TapestryView;
