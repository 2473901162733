/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Amplify } from "@aws-amplify/core";
import App from "./app/app";
import Theme from "./styles/theme";
import RootProvider from "./context/root-provider";
import awsconfig from "./aws-config";
import "./index.css";

Amplify.configure(awsconfig);

const Main = () => (
  <RootProvider>
    <ThemeProvider theme={Theme}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </RootProvider>
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Main />);
