import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";

const Theme = createTheme({
  palette: {
    common: {
      highlight: colors.highlightColor,
      orange: colors.blockOrange,
      green: colors.navBarBlue,
    },
    primary: {
      main: colors.blue,
    },
    secondary: {
      main: colors.navBarBlue,
    },
    error: {
      main: colors.darkRed,
    },
  },
  typography: {
    fontFamily: "Nunito, sans-serif",
  },
  components: {
    MuiToolbar: {
      variants: [
        {
          props: { variant: "footer" },
          style: {
            backgroundColor: colors.footerColor,
            padding: "0.9em 8.2vw !important",
            color: "silver",
            fontSize: "0.8rem",
            fontFamily: "Nunito, sans-serif",
            textAlign: "left",
            lineHeight: "1.2rem",
            marginTop: "16px",
          },
        },
      ],
    },
    MuiLink: {
      variants: [
        {
          props: { variant: "email" },
          style: {
            textTransform: "lowercase",
            "&:focus": {
              color: colors.errorTextColor,
            },
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.navBarBlue,
          height: "64px",
          minHeight: "64px",
        },
      },
    },
  },
});
export default Theme;
