import { useState, useMemo, useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import AppBackService from "../services/appback";
import { RootContext } from "../context/root-provider";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  Button,
  Skeleton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Grid,
} from "@mui/material";
import Taps from "../components/Taps";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import CalendarImage from "../assets/calendar_image.png";
import CreateTapModal from "../components/CreateTapModal";
import StartFyreModal from "../components/StartFyreModal";
import { useSnackbar } from "../components/AlertNotification";
import { useMeetingActions, useTapActions, useFilter } from "../hooks/hooks";
import useFeatureFlag from "../hooks/useFeatureFlag";
import { colors } from "../styles/colors";
import { commonStyles } from "../styles/commonStyles";

const styles = {
  addMeetingButton: {
    background: colors.secondaryGreen,
    height: "44px",
    color: colors.white,
    textTransform: "none",
    fontWeight: 700,
    fontSize: "16px",
    padding: "0 12px",
    "&:hover": {
      background: colors.thickGreen,
    },
  },
  actions: {
    display: "flex",
    alignSelf: "flex-end",
  },
  actionButton: {
    color: colors.secondaryGreen,
    pointerEvents: "all",
  },
  purpose: {
    marginBottom: "10px",
    fontSize: "14px",
    lineHeight: "16px",
  },
  noDataHeaderStyle: {
    fontSize: "36px",
    lineHeight: "44px",
    color: colors.lightBlack,
    fontWeight: "700",
  },
  calendarImageContainerStyle: { justifyContent: "center", textAlign: "center", display: "flex", marginTop: "-40px" },
  calendarImageStyle: { maxWidth: "400px" },
  noDataSubtitleStyle: {
    fontSize: "24px",
    lineHeight: "28px",
    color: colors.lightBlack,
    fontWeight: "700",
    float: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginTop: "-45px",
  },
  zoomButtonStyle: {
    backgroundColor: colors.secondaryGreen,
    color: colors.white,
    marginTop: "35px",
    "&:hover": { backgroundColor: colors.thickGreen },
  },
  accordionStyle: {
    backgroundColor: colors.lightBlue,
    minWidth: "870px",
  },
  meetingSkeltonStyle: {
    marginBottom: "20px",
    width: "870px",
    maxWidth: "calc(100vw - 128px)",
  },
  mainGridContainer: {
    textAlign: "center",
  },
  filterIcon: {
    marginLeft: "16px",
  },
  mettingsLayout: {
    maxWidth: "1080px",
  },

  error: {
    color: colors.red,
  },
};

const Timeline = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [createTapModalOpen, setCreateTapModalOpen] = useState(false);
  const [startFyreModalOpen, setStartFyreModalOpen] = useState(false);
  const [creatingDoc, setCreatingDoc] = useState();
  const [expandedAccordions, setExpandedAccordions] = useState([]);

  const { userInfo, meetingsSynced, loggingIn, setSelectedTab } = useContext(RootContext);
  const { showSnackbar, snackbarEl } = useSnackbar();
  const { filter, filterEl } = useFilter("Filter Meetings");
  const featureAccepted = useFeatureFlag(userInfo);

  useEffect(() => {
    setSelectedTab("meetings");
  }, [setSelectedTab]);

  // document action handlers

  const update = useCallback(
    (updates, id) => {
      if (!updates) {
        showSnackbar("Unable to update meeting", "error");
      } else {
        setMeetings((meetings) => {
          const meetingsCopy = [...meetings];
          const updatedDocIndex = meetingsCopy.findIndex((meeting) => meeting.id === id);
          Object.assign(meetingsCopy[updatedDocIndex], updates);
          if (!expandedAccordions[updatedDocIndex] && updates.purpose) {
            setExpandedAccordions((expandedAccordions) =>
              expandedAccordions.map((a, i) => (i === updatedDocIndex ? true : a))
            );
          }
          return meetingsCopy;
        });
        showSnackbar("Meeting updated", "success");
      }
    },
    [expandedAccordions, showSnackbar]
  );

  const resolveDelete = useCallback(
    (meetingId) => {
      if (!meetingId) {
        showSnackbar("Unable to delete document", "error");
      } else {
        setMeetings((meetings) => {
          const meetingsCopy = [...meetings];
          const removeIndex = meetingsCopy.findIndex((meeting) => meeting.id === meetingId);
          meetingsCopy.splice(removeIndex, 1);
          setExpandedAccordions((expandedAccordions) => {
            const expandedAccordionsCopy = [...expandedAccordions];
            expandedAccordionsCopy.splice(removeIndex, 1);
            return expandedAccordionsCopy;
          });
          return meetingsCopy;
        });
        showSnackbar("Meeting deleted", "success");
      }
    },
    [showSnackbar]
  );

  const { setDeleting, deleteConfirm, setEditingMeeting, editMeetingDialog } = useMeetingActions(update, resolveDelete);

  const deleteMeeting = useCallback(
    (title, meetingId) => {
      setDeleting({ title, meetingId });
    },
    [setDeleting]
  );

  const resolveTapDelete = useCallback(
    (tapId) => {
      if (!tapId) {
        showSnackbar("Unable to delete Tapestry", "error");
      } else {
        setMeetings((meetings) => {
          const meetingsCopy = [...meetings];
          const docIndex = meetingsCopy.findIndex((meeting) => meeting.taps?.find((tap) => tap.tapId === tapId));
          const tapIndex = meetingsCopy[docIndex].taps?.findIndex((tap) => tap.tapId === tapId);
          if (tapIndex >= 0) {
            meetingsCopy[docIndex].taps?.splice(tapIndex, 1);
            meetingsCopy[docIndex] = { ...meetingsCopy[docIndex] };
          }
          return meetingsCopy;
        });
        showSnackbar("Tapestry deleted", "success");
      }
    },
    [showSnackbar]
  );

  const { setDeletingTap, deleteTapConfirm } = useTapActions(resolveTapDelete);

  const deleteTap = useCallback(
    (tapId, title) => {
      setDeletingTap({ title, tapId });
    },
    [setDeletingTap]
  );

  // initiate document actions

  const editMeeting = useCallback(
    (meeting) => {
      setEditingMeeting(meeting);
    },
    [setEditingMeeting]
  );

  const fetchMeetings = useCallback(
    async (showLoading = true) => {
      if (userInfo?.username) {
        if (showLoading) {
          setLoading(true);
        }
        const data = await AppBackService.getUserTimeline(userInfo.username);
        if (!data || data.error) {
          setError(true);
        } else {
          if (data.meetings?.length) {
            data.meetings.forEach((meeting) => {
              const startTime = dayjs(isNaN(+meeting.startTime) ? meeting.startTime : +meeting.startTime).format(
                "llll"
              );

              meeting.startTime = dayjs(startTime).isValid() ? startTime : meeting.startTime;
            });
            setExpandedAccordions(data.meetings.map((meeting) => !(!meeting.taps?.length && !meeting.purpose)));
            setMeetings(data.meetings);
          }
        }
        if (showLoading) {
          setLoading(false);
        }
      }
    },
    [userInfo]
  );

  // init, fetch meetings
  useEffect(() => {
    if (userInfo?.username && !loggingIn) {
      fetchMeetings();
    }
  }, [fetchMeetings, loggingIn, meetingsSynced, userInfo]);

  const expandAll = useCallback(() => {
    setExpandedAccordions(meetings.map((meeting) => !(!meeting.taps?.length && !meeting.purpose)));
  }, [meetings]);

  const collapseAll = useCallback(() => {
    setExpandedAccordions((expandedAccordions) => expandedAccordions.map(() => false));
  }, []);

  // elements

  const meetingsSkeleton = useMemo(
    () =>
      Array.from(new Array(3)).map((_d, i) => (
        <Box sx={styles.meetingSkeltonStyle} key={i}>
          <Skeleton variant="rectangular" width="100%" height={120} />
        </Box>
      )),
    []
  );

  const meetingsEls = useMemo(
    () =>
      meetings
        .filter(
          (meeting) =>
            !filter ||
            meeting.title.toLowerCase().includes(filter.toLowerCase()) ||
            meeting.startTime.toLowerCase().includes(filter.toLowerCase())
        )
        .map((meeting, i) => (
          <MeetingAccordion
            key={meeting.id || i}
            meeting={meeting}
            index={i}
            deleteMeeting={deleteMeeting}
            editMeeting={editMeeting}
            setCreateTapModalOpen={setCreateTapModalOpen}
            setCreatingDoc={setCreatingDoc}
            deleteTap={deleteTap}
            expanded={expandedAccordions[i]}
            setExpandedAccordions={setExpandedAccordions}
          />
        )),
    [meetings, filter, deleteMeeting, editMeeting, deleteTap, expandedAccordions]
  );

  const errorEl = useMemo(
    () => (
      <Box sx={styles.error}>
        <ErrorIcon />
      </Box>
    ),
    []
  );

  const noDataPage = useMemo(
    () => (
      <>
        <Grid container sx={styles.mainGridContainer}>
          <Grid item xs={0} sm={2} md={3} lg={3} />
          <Grid item xs={12} sm={8} md={6} lg={6}>
            <Typography sx={styles.noDataHeaderStyle}>No Meetings Found</Typography>
            <div style={styles.calendarImageContainerStyle}>
              <img src={CalendarImage} style={styles.calendarImageStyle} alt="calendar" />
            </div>
            <Typography sx={styles.noDataSubtitleStyle}>
              Schedule a meeting in Zoom to see your upcoming meetings here
            </Typography>
            <Button component={Link} href="zoomus://" sx={styles.zoomButtonStyle}>
              Open Zoom
            </Button>
          </Grid>
          <Grid item xs={0} sm={2} md={3} lg={3} />
        </Grid>
      </>
    ),
    []
  );

  return error ? (
    errorEl
  ) : (
    <Box sx={{ ...commonStyles.pageContainer, textAlign: meetings.length ? "left" : "center" }}>
      {meetings.length || loading ? (
        <Box>
          <Box sx={commonStyles.headerContainer}>
            <Box sx={commonStyles.titleContainer}>
              <Typography sx={commonStyles.pageHeader}>Meetings</Typography>
              <Box sx={styles.filterIcon}>{filterEl}</Box>
            </Box>
            {featureAccepted && (
              <Button
                size="medium"
                startIcon={<WhatshotIcon />}
                sx={styles.addMeetingButton}
                onClick={() => setStartFyreModalOpen(true)}>
                Start Fyre
              </Button>
            )}
          </Box>
          {expandedAccordions.length && meetingsEls.length ? (
            <Box sx={commonStyles.pageActions}>
              <Button
                sx={commonStyles.accordionControl}
                size="small"
                onClick={expandAll}
                disabled={expandedAccordions.every((a, i) => a || (!meetings[i].taps?.length && !meetings[i].purpose))}>
                Expand All
              </Button>
              <Button
                sx={commonStyles.accordionControl}
                size="small"
                onClick={collapseAll}
                disabled={expandedAccordions.every((a) => !a)}>
                Collapse All
              </Button>
            </Box>
          ) : null}
          <Box sx={styles.mettingsLayout}>
            {loading ? (
              meetingsSkeleton
            ) : meetingsEls.length ? (
              meetingsEls
            ) : (
              <Typography sx={commonStyles.emptyFilter}>No meetings found</Typography>
            )}
          </Box>
          <CreateTapModal
            open={createTapModalOpen}
            setOpen={setCreateTapModalOpen}
            doc={creatingDoc}
            refreshCallback={fetchMeetings}
            showSnackbar={showSnackbar}
          />
          {featureAccepted && (
            <StartFyreModal
              open={startFyreModalOpen}
              setOpen={setStartFyreModalOpen}
              refreshCallback={fetchMeetings}
              showSnackbar={showSnackbar}
            />
          )}
        </Box>
      ) : (
        noDataPage
      )}
      {editMeetingDialog}
      {deleteConfirm}
      {deleteTapConfirm}
      {snackbarEl}
    </Box>
  );
};

const MeetingAccordion = ({
  meeting,
  index,
  deleteMeeting,
  editMeeting,
  setCreateTapModalOpen,
  setCreatingDoc,
  deleteTap,
  expanded,
  setExpandedAccordions,
}) => {
  const handleChange = useCallback(() => {
    setExpandedAccordions((expandedAccordions) => {
      const expandedAccordionsCopy = [...expandedAccordions];
      expandedAccordionsCopy[index] = !expandedAccordionsCopy[index];
      return expandedAccordionsCopy;
    });
  }, [index, setExpandedAccordions]);

  return (
    <Accordion
      sx={{ pointerEvents: !meeting.taps?.length ? "none" : "all", ...styles.accordionStyle }}
      expanded={expanded}
      onChange={handleChange}>
      <AccordionSummary
        sx={commonStyles.accordionSummary}
        expandIcon={<ExpandMoreIcon sx={{ visibility: !meeting.taps?.length ? "hidden" : "visible" }} />}
        aria-controls={`meeting${index}-content`}
        id={`meeting${index}-header`}>
        <Box sx={commonStyles.bullet}></Box>
        <Typography sx={commonStyles.docTitle}>{meeting.title}</Typography>
        <Typography sx={commonStyles.docDate}>{meeting.startTime}</Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box sx={styles.actions}>
          <IconButton
            sx={styles.actionButton}
            title="Edit title"
            aria-label="Edit title"
            onClick={(e) => {
              e.stopPropagation();
              editMeeting(meeting);
            }}>
            <EditIcon />
          </IconButton>
          <IconButton
            sx={{
              ...styles.actionButton,
              marginRight: !meeting.docId ? "0" : "10px",
              "&:hover": {
                color: colors.red,
              },
            }}
            title="Delete Meeting"
            aria-label="Delete Meeting"
            onClick={(e) => {
              e.stopPropagation();
              deleteMeeting(meeting.title, meeting.id);
            }}>
            <CancelIcon />
          </IconButton>
          {meeting.docId && (
            <Button
              variant="contained"
              sx={commonStyles.createTapBtn}
              size="medium"
              onClick={(e) => {
                e.stopPropagation();
                setCreatingDoc(meeting);
                setCreateTapModalOpen(true);
              }}>
              Create Tapestry
            </Button>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {meeting.purpose && <Typography sx={styles.purpose}>{meeting.purpose}</Typography>}
          {meeting.taps && <Taps taps={meeting.taps} deleteTap={deleteTap} />}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
MeetingAccordion.propTypes = {
  meeting: PropTypes.object,
  index: PropTypes.number,
  deleteMeeting: PropTypes.func,
  editMeeting: PropTypes.func,
  setCreateTapModalOpen: PropTypes.func,
  setCreatingDoc: PropTypes.func,
  deleteTap: PropTypes.func,
  expanded: PropTypes.bool,
  setExpandedAccordions: PropTypes.func,
};

export default Timeline;
