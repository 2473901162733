import { useCallback, useState, useEffect, useMemo } from "react";
import AppBackService from "../services/appback";

const DISPLAY_NOTIF_TYPES = ["tapgen"];

export default function useNotifications(userInfo, notifications, setNotifications) {
  const [intervalTimeouts, setIntervalTimeouts] = useState([]);

  // cancel all intervals on unmount
  useEffect(() => {
    return () => {
      for (const intervalTimeout of intervalTimeouts) {
        clearInterval(intervalTimeout);
      }
    };
  }, [intervalTimeouts]);

  const notifInterval = useCallback(
    (callback, showSnackbar, intervalTime = 10000, numIntervals = 6, keepAlive = false) => {
      let intervalCount = 0;
      const intervalTimeout = setInterval(async () => {
        const data = await AppBackService.getUserNotifications(userInfo.username);

        if (data && data.notifs?.length) {
          for (const notif of data.notifs) {
            // show snackbars
            if (
              notif.type === "tapgen" &&
              notif.tap &&
              notif.action === "create" &&
              notif.msg.toLowerCase() === "success"
            ) {
              showSnackbar(`Tapestry "${notif.tap.title}" created`, "success", "View", () => {
                window.location.href = "/tapestry/" + notif.tap.tapId;
              });
              callback && callback(data);
            } else if (notif.msg) {
              showSnackbar(notif.msg, !notif.severity || notif.severity === "status" ? "info" : notif.severity);
            }

            // stop the notification interval if a keeper notification is received (meeting is ended)
            if (notif.type === "keeper") {
              cancelTimeout();
            }
          }
        }

        setNotifications((notifications) => [...data.notifs, ...notifications]);
        intervalCount++;
        if (!keepAlive || intervalCount > numIntervals - 1) {
          cancelTimeout();
        }

        function cancelTimeout() {
          setIntervalTimeouts((intervalTimeouts) => intervalTimeouts.filter((t) => t !== intervalTimeout));
          clearInterval(intervalTimeout);
        }
      }, intervalTime);
      setIntervalTimeouts((intervalTimeouts) => [...intervalTimeouts, intervalTimeout]);
    },
    [setNotifications, userInfo]
  );

  const displayNotifs = useMemo(
    () => notifications.filter((n) => DISPLAY_NOTIF_TYPES.includes(n.type)),
    [notifications]
  );

  return { notifInterval, displayNotifs };
}
