import { useMemo, useContext, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { RootContext } from "../context/root-provider";
import NotificationCard from "./NotificationCard";
import AppBackService from "../services/appback";

const styles = {
  boxStyle: { padding: "0 16px", width: "320px" },
  noNotifiyTextStyle: { padding: "20px", textAlign: "center" },
};

const NotificationTray = ({ close }) => {
  const { userInfo, meetingsSynced, loggingIn, displayNotifs, setNotifications } = useContext(RootContext);

  // init fetch notifications
  useEffect(() => {
    async function fetchNotifs() {
      const data = await AppBackService.getUserNotifications(userInfo.username);
      if (data && !data.error && data.notifs) {
        setNotifications(data.notifs);
      }
    }

    if (userInfo?.username && meetingsSynced && !loggingIn) {
      fetchNotifs();
    }
  }, [loggingIn, meetingsSynced, setNotifications, userInfo]);

  const remove = useCallback(
    (notifId) => {
      setNotifications((notifications) => {
        const notifsCopy = [...notifications];
        notifsCopy.splice(
          notifsCopy.findIndex((n) => n.notifId === notifId),
          1
        );
        if (!notifsCopy.length) {
          close();
        }
        return notifsCopy;
      });
    },
    [close, setNotifications]
  );

  const notificationEls = useMemo(
    () =>
      displayNotifs.map((n, i) => (
        <NotificationCard
          key={n.notifId}
          notification={n}
          remove={remove}
          lastNotification={i === displayNotifs.length - 1}
        />
      )),
    [displayNotifs, remove]
  );

  return (
    <Box sx={styles.boxStyle}>
      {displayNotifs.length ? notificationEls : <Box sx={styles.noNotifiyTextStyle}>No notifications</Box>}
    </Box>
  );
};
NotificationTray.propTypes = {
  close: PropTypes.func,
};

export default NotificationTray;
