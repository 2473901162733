import { useState, useContext, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Grid, IconButton, InputAdornment, Button, Checkbox, TextField, Link, Box } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import * as yup from "yup";
import { RootContext } from "../context/root-provider";
import { colors } from "../styles/colors";
import { commonStyles } from "../styles/commonStyles";

const styles = {
  centerGridItemStyle: {
    borderRadius: "10px",
    paddingLeft: "0px",
    paddingRight: "0px",
    background: colors.lightBlue,
  },
  formStyle: { width: "100%" },
  loginButtonStyle: {
    width: "72%",
    height: "47px",
    backgroundColor: colors.secondaryGreen,
    marginBottom: "10px",
    "&:hover": { backgroundColor: colors.thickGreen },
  },
  linkStyle: { color: colors.secondaryGreen },
  textStyle: {
    color: colors.secondaryGreen,
    fontSize: "14px",
    textAlign: "center",
  },
  error: {
    color: colors.errorTextColor,
  },
};

const validationSchema = yup.object({
  email_address: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});
const authValidationSchema = yup.object({
  auth_code: yup.string("Enter verification code").required("A verification code is required"),
});

const LoginWithAuth = () => {
  const [values, setValues] = useState({
    showPassword: false,
    user: {},
    error: "",
    auth_code: "",
    hideAuthCode: true,
    phone_number: "",
    checkedB: false,
    email_address: "",
    password: "",
    requestedOTP: false,
  });
  const { setAuthStatus } = useContext(RootContext);

  let location = useLocation();
  let signUpUrlWithQueryParams = "/signup" + location.search;

  const formik = useFormik({
    initialValues: {
      email_address: "",
      password: "",
      requestedOTP: false,
    },
    validationSchema,
    onSubmit: (login) => {
      handleLogin(login);
    },
  });
  const authFormik = useFormik({
    initialValues: {
      auth_code: "",
    },
    validationSchema: authValidationSchema,
    onSubmit: (login) => {
      handleAuthCode(login);
    },
  });

  const handleClickShowPassword = useCallback(() => {
    setValues((v) => ({ ...v, showPassword: !v.showPassword }));
  }, []);

  const handleMouseDownPassword = useCallback((event) => {
    event.preventDefault();
  }, []);

  const onKeyPress = useCallback((e) => {
    if (e.which === 13) {
      //handleSubmit();
    }
  }, []);

  const onLoginSuccess = useCallback(() => {
    setAuthStatus(true);
    localStorage.setItem("activeTime", +new Date());
  }, [setAuthStatus]);

  const handleLogin = useCallback(
    async (model) => {
      setValues((v) => ({ ...v }));
      try {
        const user = await Auth.signIn(model.email_address.toLowerCase(), model.password);

        if (user.preferredMFA !== "NOMFA" && user.challengeParam !== undefined) {
          setValues({
            user,
            hideAuthCode: false,
            phone_number: user.challengeParam.CODE_DELIVERY_DESTINATION,
            email_address: model.email_address,
            password: model.password,
            requestedOTP: model.requestedOTP,
          });
        } else {
          setValues((v) => ({ ...v, user }));
          onLoginSuccess();
        }
      } catch (error) {
        setValues((v) => ({ ...v, error: error.message }));
      }
    },
    [onLoginSuccess]
  );

  const handleCheck = useCallback((event) => {
    setValues((v) => ({ ...v, [event.target.name]: event.target.checked }));
  }, []);

  const handleAuthCode = useCallback(
    (model) => {
      Auth.confirmSignIn(values.user, model.auth_code, values.user.challengeName)
        .then((user) => {
          if (values.checkedB) {
            user.setDeviceStatusRemembered({
              onSuccess() {
                onLoginSuccess();
              },

              onFailure(err) {
                alert(err);
              },
            });
          } else {
            user.setDeviceStatusNotRemembered({
              onSuccess() {
                onLoginSuccess();
              },

              onFailure(err) {
                alert(err);
              },
            });
          }
        })
        .catch((err) => {
          setValues((v) => ({ ...v, error: err.message }));
        });
    },
    [onLoginSuccess, values.checkedB, values.user]
  );

  const handleCancel = useCallback(() => {
    setValues((v) => ({ ...v, hideAuthCode: true }));
  }, []);

  const newAccessCode = useCallback(() => {
    const model = {
      email_address: values.email_address,
      password: values.password,
      requestedOTP: true,
    };
    handleLogin(model);
  }, [handleLogin, values.email_address, values.password]);

  const res = useMemo(() => {
    const prevLoggedIn = localStorage.getItem("bypassWelcome") === "true";
    return (
      <div>
        {values.hideAuthCode && (
          <div>
            <p className="error" style={styles.error}>
              {values.error ? "Incorrect user/password combination" : ""}
            </p>
            {prevLoggedIn && <p style={commonStyles.signupLoginHeader}>Welcome Back</p>}
            <div>
              <form noValidate style={styles.formStyle} onSubmit={formik.handleSubmit}>
                <Box mt={1} mb={2}>
                  <TextField
                    variant="outlined"
                    sx={commonStyles.textFieldStyle}
                    inputProps={{ style: { height: "18px" } }}
                    id="email_address"
                    name="email_address"
                    placeholder="Email Address"
                    value={formik.values.email_address}
                    onChange={formik.handleChange}
                    error={formik.touched.email_address && Boolean(formik.errors.email_address)}
                    helperText={formik.touched.email_address && formik.errors.email_address}
                    type="email"
                  />
                </Box>
                <Box mt={1} mb={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    style={commonStyles.textFieldStyle}
                    inputProps={{ style: { height: "18px" } }}
                    id="password"
                    name="password"
                    placeholder="Password"
                    type={values.showPassword ? "text" : "password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    onKeyPress={(e) => onKeyPress(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="MuiIconButton-edgeEnd">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword()}
                            onMouseDown={(e) => handleMouseDownPassword(e)}>
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: { fieldset: { borderColor: colors.secondaryGreen } },
                    }}
                  />
                </Box>
                <Button
                  className="login_button formcontrol-button"
                  variant="contained"
                  sx={styles.loginButtonStyle}
                  id="login_button"
                  type="submit">
                  LOGIN
                </Button>
              </form>
            </div>
            <Link href="/forgot-password" sx={styles.linkStyle}>
              Forgot Password?
            </Link>
            <br />
            <br />
            <p style={{ fontSize: "14px" }}>
              Don't have an account? &nbsp;
              <Link component="a" className="signup_button" href={signUpUrlWithQueryParams} sx={styles.linkStyle}>
                Sign up
              </Link>
            </p>
          </div>
        )}

        {!values.hideAuthCode && (
          <div>
            <form onSubmit={authFormik.handleSubmit}>
              {values.requestedOTP && (
                <p style={styles.textStyle}>New verification code has been sent to {values.phone_number}</p>
              )}
              {!values.requestedOTP && (
                <p style={styles.textStyle}>A verification code has been sent to {values.phone_number}</p>
              )}
              <Grid style={{ textAlign: "center", fontSize: "14px" }}>
                If you have not received a code, you can{" "}
                <Link onClick={() => newAccessCode()} sx={styles.linkStyle}>
                  request a new code
                </Link>
              </Grid>
              <Grid
                style={{
                  borderRadius: "10px",
                }}>
                <p className="error" style={styles.error}>
                  {values.error}
                </p>
                <TextField
                  variant="outlined"
                  style={{ width: "80%" }}
                  id="auth_code"
                  name="auth_code"
                  label="Enter Verification Code"
                  value={authFormik.values.auth_code}
                  onChange={authFormik.handleChange}
                  error={authFormik.touched.auth_code && Boolean(authFormik.errors.auth_code)}
                  helperText={authFormik.touched.auth_code && authFormik.errors.auth_code}
                />
                <br />
                <br />
                <Checkbox
                  checked={values.checkedB || false}
                  onChange={handleCheck}
                  name="checkedB"
                  color="primary"
                  id="trust_device_chk"
                />
                <b>Trust this device and skip this step in the future</b>.
                <Grid container spacing={1} style={{ marginBottom: "10px", marginTop: "10px" }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid>
                      <Button
                        className="signup_button"
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                        onClick={() => handleCancel()}
                        id="concel_button">
                        Cancel
                      </Button>
                      <Button
                        className="signup_button"
                        style={{
                          color: colors.white,
                          border: "none",
                          backgroundColor: "black",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                        type="submit"
                        id="continue_button">
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </div>
        )}
      </div>
    );
  }, [
    authFormik,
    formik,
    handleCancel,
    handleCheck,
    handleClickShowPassword,
    handleMouseDownPassword,
    newAccessCode,
    onKeyPress,
    values,
  ]);

  return res;
};

export default LoginWithAuth;
