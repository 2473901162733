import { useState, useEffect, useContext, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TextField } from "@mui/material";
import AppBackService from "../services/appback";
import { RootContext } from "../context/root-provider";

const styles = {
  textField: {
    width: "100%",
    marginBottom: "10px",
    marginTop: "6px",
  },
  heading: {
    marginTop: "10px",
  },
};

const StartFyreModal = ({
  open,
  setOpen,
  presetMeetingId,
  presetPassword,
  presetTitle,
  refreshCallback,
  showSnackbar,
}) => {
  const [meetingId, setMeetingId] = useState(presetMeetingId || "");
  const [password, setPassword] = useState(presetPassword || "");
  const [title, setTitle] = useState(presetTitle || "");
  const [formInvalid, setFormInvalid] = useState(false);
  const dialogContentEl = useRef();

  const { userInfo, notifInterval } = useContext(RootContext);

  const closeModal = useCallback(() => {
    setFormInvalid(false);
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (formInvalid && dialogContentEl.current) {
      dialogContentEl.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [formInvalid]);

  const startFyre = useCallback(async () => {
    // validate input
    if (!title || !meetingId) {
      setFormInvalid(true);
      return;
    } else {
      setFormInvalid(false);
    }

    if (meetingId) {
      const resp = await AppBackService.startFyre(userInfo?.username, {
        meeting_id: meetingId,
        title,
        meeting_pw: password,
      });

      if (resp && !resp.error) {
        console.log("FYRE INFO:", {
          userId: userInfo?.username,
          docId: resp.docId,
        });
      }

      if (resp && !resp.error) {
        showSnackbar("Starting Fyre...", "info");
        refreshCallback();
      } else {
        showSnackbar("Unable to start Fyre", "error");
      }
      // start notification polling
      // 20 seconds * 90 intervals = 30 minutes for meeting to run
      notifInterval(refreshCallback, showSnackbar, 20000, 90, true);
      setOpen(false);
    }
  }, [meetingId, notifInterval, password, refreshCallback, setOpen, showSnackbar, title, userInfo]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={closeModal}>
      <DialogTitle>Start Fyre</DialogTitle>
      <DialogContent ref={dialogContentEl}>
        <DialogContentText sx={styles.heading}>
          This is an alpha feature - it will only work if pre-arranged wit the Tapestry team.
        </DialogContentText>
        {!presetTitle ? (
          <TextField
            sx={styles.textField}
            error={formInvalid && !title}
            helperText={formInvalid && !title && "Please enter a meeting title"}
            label="Meeting Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        ) : (
          <DialogContentText sx={{ ...styles.heading, marginTop: 0 }}>Meeting: {presetTitle}</DialogContentText>
        )}
        {!presetMeetingId && (
          <TextField
            sx={styles.textField}
            error={formInvalid && !meetingId}
            helperText={formInvalid && !meetingId && "Please enter a meeting ID"}
            label="Meeting ID"
            value={meetingId}
            onChange={(e) => setMeetingId(e.target.value)}
          />
        )}
        {!presetPassword && (
          <TextField
            sx={styles.textField}
            label="Meeting Password (optional)"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button onClick={startFyre}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};
StartFyreModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  presetMeetingId: PropTypes.string,
  presetPassword: PropTypes.string,
  presetTitle: PropTypes.string,
  refreshCallback: PropTypes.func,
  showSnackbar: PropTypes.func,
};

export default StartFyreModal;
