import { useState, useMemo, useCallback, useRef, useEffect, useContext } from "react";
import { styled } from "@mui/system";
import { useParams, Navigate } from "react-router-dom";
import { useGesture } from "@use-gesture/react";
import { parse } from "transform-parser";
import AppBackService from "../services/appback";
import { RootContext } from "../context/root-provider";
import classNames from "classnames";
import dayjs from "dayjs";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { Box, Typography, CircularProgress, Skeleton, Button } from "@mui/material";
import { useTapActions } from "../hooks/hooks";
import ErrorIcon from "@mui/icons-material/Error";
import ChatIcon from "@mui/icons-material/Chat";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import IosShareIcon from "@mui/icons-material/IosShare";
import ShareModal from "../components/ShareModal";
import { useSnackbar } from "../components/AlertNotification";
import DocumentText from "../components/DocumentText";
import InlineEdit from "../components/InlineEdit";
import TapestryView, { getTapestryDimensions } from "../components/TapestryView";
import pointerIcon from "../assets/pointer-green.svg";
import { colors } from "../styles/colors";
import "./DetailPage.scss";

const NUM_PHRASE_OPTIONS = 20;
const primaryGreen = colors.primaryGreen;
const defaultMarkupHeight = 120;

const styles = {
  tapestryActionStyle: {
    cursor: "pointer",
    marginLeft: "20px",
    display: "inline-block",
    verticalAlign: "middle",
    "&:hover p": {
      color: primaryGreen,
    },
  },
  alignMiddleStyle: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  editPanelStyle: {
    width: "100%",
    marginTop: "20px",
  },
  editPanelHeaderStyle: {
    fontSize: "24px",
    fontWeight: "700",
    marginBottom: "24px",
    textAlign: "left",
  },
  thumbnailStyle: {
    height: "120px",
    marginRight: "20px",
    opacity: 0.75,
  },
  transcriptHead: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "8px",
    marginLeft: "0px !important",
  },
  phraseOptionsStyle: {
    width: "350px",
    background: colors.white,
    boxShadow: `0px 2px 6px ${colors.borderGrey};`,
    padding: "16px",
    paddingTop: "14px",
    borderRadius: "4px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  phraseSelectStyle: {
    fontSize: "16px",
    marginBottom: "14px",
    color: colors.darkGrey,
    float: "left",
    fontWeight: "bold",
  },
  closeIcon: {
    float: "right",
    color: colors.grey,
    cursor: "pointer",
  },
  tapestryMainPage: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    textAlign: "left",
    boxSizing: "border-box",
    " p": {
      display: "inline-block",
      verticalAlign: "middle",
      marginLeft: "5px",
    },
    "&.un-editable .tapestry-container": {
      "& .text.editable, & .clickable-text, & .editable-textpath, & .editable": {
        pointerEvents: "none !important",
      },
    },
    " .edit-tapestry-container": {
      flexDirection: "row",
      " .side-panel-container": {
        flexBasis: "25%",
        width: "25%",
        minWidth: "25%",
        boxSizing: "border-box",
        marginLeft: "10px",
      },
    },
    ".svg-container": {
      display: "block",
      margin: "0 auto",

      ".viz-text": {
        "&.editable": {
          pointerEvents: "all",
          cursor: "pointer",

          "&:hover tspan": {
            fill: primaryGreen,
          },
        },
      },
      ".editable-textpath": {
        pointerEvents: "all",
        cursor: "pointer",

        "&:hover": {
          fill: primaryGreen,

          textPath: {
            fill: primaryGreen,
          },
        },
      },
    },
  },
  editPanelStyles: {
    width: "100%",
    boxSizing: "border-box",
    paddingLeft: "30px",
    marginBottom: "72px",
  },
  detailPageStyle: {
    padding: "0 10px 10px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    boxSizing: "border-box",
  },
  tapestryContainerStyles: {
    display: "flex",
    width: "100%",
    position: "relative",
  },
  editPanel: {
    display: "flex",
  },
  phraseOptionsList: {
    borderRadius: "3px",
    border: `1px solid ${colors.primaryGreen}`,
    maxHeight: "60vh",
    width: "100%",
    overflowY: "auto",
  },
  phraseOptionsListItem: {
    padding: "10px",
    fontSize: "14px",
    cursor: "pointer",
    textAlign: "left",
    "&:hover": {
      backgroundColor: colors.selectionPhraseColor,
    },
    "&.selected": {
      backgroundColor: colors.selectedColor,
    },
  },
  noPhraseOptions: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 0",
  },
  errorStyle: {
    color: colors.errorTextColor,
  },
  tapestryTitle: { width: "100%", marginBottom: "10px", position: "relative" },
  titleTextStyle: { fontSize: "24px", fontWeight: "bold" },
  tapestryDataStyle: { display: "inline-block", verticalAlign: "baseline", marginLeft: "10px" },
  tapestrySubtitle: {
    width: "100%",
    height: "44px",
    marginBottom: "20px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  tapestryHeader: { width: "100%" },
  phraseSelHeader: { height: "32px" },
  circularProgressStyle: { margin: "40px auto" },
  tapestryActionMainStyle: { whiteSpace: "nowrap", display: "flex", justifyContent: "flex-end", flexGrow: 1 },
  transcriptStyle: {
    display: "flex",
    flexDirection: "column",
  },
  markupsStyle: {
    display: "flex",
    flexWrap: "wrap",
    "& img": { height: defaultMarkupHeight + "px", touchAction: "none" },
    backgroundColor: colors.white,
    padding: "10px",
    border: "1px solid " + primaryGreen,
    alignContent: "flex-start",
  },
  dummyMarkupStyle: {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 1000,
    height: defaultMarkupHeight + "px",
    transform: "translate(-50%, -50%)",
  },
  tapActionButtonContainer: {
    flexGrow: 1,
  },
  saveTapButton: {
    background: colors.secondaryGreen,
    height: "44px",
    color: colors.white,
    textTransform: "none",
    fontWeight: 700,
    fontSize: "16px",
    padding: "0 12px",
    "&:hover": {
      background: colors.thickGreen,
    },
  },
};
const breakpoint = 1200;

const ViewTranscriptMedia = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    ".edit-tapestry-container": {
      flexDirection: "column",

      ".side-panel-container": {
        width: "100% !important",
        minWidth: "100% !important",
        flexBasis: "100% !important",
        marginLeft: "0 !important",
        marginTop: "10px",
        maxHeight: "600px",
      },
    },
  },
}));

const DetailPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [editingTapestry, setEditingTapestry] = useState(false);
  const [searchingPhrases, setSearchingPhrases] = useState(false);
  const [selectedText, setSelectedText] = useState(null);
  const [tapestryData, setTapestryData] = useState({});
  const [doc, setDoc] = useState({});
  const [title, setTitle] = useState();
  const [phraseOptions, setPhraseOptions] = useState();
  const [updatedPhrase, setUpdatedPhrase] = useState();
  const [tapestryHeight, setTapestryHeight] = useState("auto");
  const [redirect, setRedirect] = useState(null);
  const [markups, setMarkups] = useState([]); // all markups
  const [markupData, setMarkupData] = useState([]); // markups in the tapestry
  const [draggingMarkup, setDraggingMarkup] = useState(null);
  const [tapEdits, setTapEdits] = useState({});
  const transcriptContainerRef = useRef();
  const markupDataCache = useRef();
  const tapDataCache = useRef();
  const titleCache = useRef();

  const { tapId } = useParams();
  const { userInfo, meetingsSynced, loggingIn, setSelectedTab } = useContext(RootContext);
  const { showSnackbar, snackbarEl } = useSnackbar();

  const resolveTapDelete = useCallback(
    (tapId) => {
      if (!tapId) {
        showSnackbar("Unable to delete Tapestry", "error");
      } else {
        setRedirect(true);
      }
    },
    [showSnackbar]
  );

  const { setDeletingTap, deleteTapConfirm } = useTapActions(resolveTapDelete);

  const deleteTap = useCallback(() => {
    setDeletingTap({ title, tapId });
  }, [setDeletingTap, tapId, title]);

  // data for editing
  const [activeLayout, setActiveLayout] = useState();
  const activeLayoutRef = useRef(activeLayout);
  const [layoutData, setLayoutData] = useState();
  const [activeBackground, setActiveBackground] = useState();
  const [backgrounds, setBackgrounds] = useState();
  const [svg, setSvg] = useState({}); // per layout
  const [textpathOptions, setTextpathOptions] = useState({}); // per layout
  const tapestryContainer = useRef();

  useEffect(() => {
    setSelectedTab("tapestries");
  }, [setSelectedTab]);

  // share modal
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);
  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);
  const imageURI = useMemo(
    () => (tapestryData.imageName ? "https://taps.tapestryai.com/" + tapestryData.imageName : ""),
    [tapestryData]
  );

  const searchingPhrasesRef = useRef(searchingPhrases);

  const textpathOptionsRef = useRef(textpathOptions);
  useEffect(() => {
    textpathOptionsRef.current = textpathOptions;
  }, [textpathOptions]);

  const backgroundsRef = useRef(backgrounds);
  useEffect(() => {
    backgroundsRef.current = backgrounds;
  }, [backgrounds]);

  const layoutDataRef = useRef(layoutData);
  useEffect(() => {
    layoutDataRef.current = layoutData;
  }, [layoutData]);

  const showPhraseOptions = useCallback((e) => {
    if (
      textpathOptionsRef.current[activeLayoutRef.current] &&
      textpathOptionsRef.current[activeLayoutRef.current][e.detail.eventID] &&
      e.detail.eventID &&
      !searchingPhrasesRef.current
    ) {
      setPhraseOptions(textpathOptionsRef.current[activeLayoutRef.current][e.detail.eventID]);
    }
  }, []);

  const hidePhraseOptions = useCallback(() => setPhraseOptions(null), []);

  const handleEscape = useCallback(
    (e) => {
      if (e.key === "Escape") {
        hidePhraseOptions();
      }
    },
    [hidePhraseOptions]
  );

  // initialize and fetch data
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const data = await AppBackService.getUserTap(userInfo.username, tapId);
      if (!data || data.error) {
        setError(true);
      } else {
        setTapestryData(data.taps);
        if (data.taps) {
          setActiveLayout(data.taps.layoutId);
          activeLayoutRef.current = data.taps.layoutId;

          // fetch tapdata
          const tapdataResp = await axios({
            method: "GET",
            url: `https://taps.tapestryai.com/${data.taps.tapId}.tapdata.json`,
          });
          if (tapdataResp.data) {
            const newTextpathOptions = { [data.taps.layoutId]: tapdataResp.data };
            setTextpathOptions(newTextpathOptions);
            tapDataCache.current = JSON.stringify(newTextpathOptions);
          }

          // fetch SVG
          const svgResp = await axios({
            method: "GET",
            url: `https://taps.tapestryai.com/${data.taps.tapId}.svg`,
          });
          if (svgResp.data) {
            setSvg((svg) => {
              const svgCopy = { ...svg };
              const dummyContainer = document.createElement("div");
              dummyContainer.innerHTML = svgResp.data;
              let newMarkupData = [];
              // parse out markup data and remove markups from SVG
              const markupsContainer = dummyContainer.querySelector("#tap-markups");
              if (markupsContainer && markupsContainer.children.length) {
                newMarkupData = [...markupsContainer.children]
                  .map((markupGroup) => {
                    if (!markupGroup.style.transform) return null;
                    const transform = parse(markupGroup.style.transform.trim());
                    const viewBox = markupGroup.querySelector("svg").viewBox.baseVal;
                    return {
                      id: markupGroup.id,
                      x: transform.translate[0],
                      y: transform.translate[1],
                      xScale: transform.scale[0],
                      yScale: transform.scale[1],
                      rotate: +transform.rotate.replace("rad", ""),
                      dimensions: {
                        width: viewBox.width,
                        height: viewBox.height,
                      },
                    };
                  })
                  .filter((markup) => markup);
                setMarkupData(newMarkupData);
              }
              markupDataCache.current = JSON.stringify(newMarkupData);
              markupsContainer?.remove();
              svgCopy[data.taps.layoutId] = dummyContainer.innerHTML;
              return svgCopy;
            });
          }

          // fetch document
          const doc = await AppBackService.getUserDoc(userInfo.username, data.taps.docId);
          if (doc?.docs) {
            setDoc(doc.docs[0]);
          }

          // fetch all markups
          const allMarkups = await AppBackService.getMarkups();
          if (allMarkups) {
            setMarkups(allMarkups);
          }

          setActiveBackground(data.taps.backgroundId);

          setTitle(data.taps.title);
          titleCache.current = data.taps.title;
        }

        window.addEventListener("showPhraseOptions", showPhraseOptions);
      }
      setLoading(false);
    }

    if (userInfo?.username && meetingsSynced && !loggingIn && tapId) {
      fetchData();
    }

    function updateTapestryDimensions(e) {
      setTapestryHeight(e.detail.height || "auto");
    }

    function searchText(e) {
      if (e.detail.text && searchingPhrasesRef.current) {
        const phrases = phrasesData(textpathOptionsRef.current[activeLayoutRef.current]);
        setSelectedText({ text: e.detail.text, indexes: phrases[e.detail.text] });
      }
    }

    window.addEventListener("tapestryDimensions", updateTapestryDimensions);
    window.addEventListener("selectText", searchText);
    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("showPhraseOptions", showPhraseOptions);
      window.removeEventListener("tapestryDimensions", updateTapestryDimensions);
      window.removeEventListener("selectText", searchText);
      window.removeEventListener("keydown", handleEscape);
    };
  }, [handleEscape, loggingIn, meetingsSynced, showPhraseOptions, tapId, userInfo]);

  const scrollToTranscript = useCallback((condition) => {
    if (
      condition &&
      transcriptContainerRef.current &&
      transcriptContainerRef.current.offsetTop > window.innerHeight * 0.85
    ) {
      window.scrollTo({
        top: transcriptContainerRef.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    searchingPhrasesRef.current = searchingPhrases;
    scrollToTranscript(searchingPhrases);
    if (!searchingPhrases) {
      setSelectedText(null);
    }
  }, [scrollToTranscript, searchingPhrases]);

  useEffect(() => {
    scrollToTranscript(selectedText?.text);
  }, [scrollToTranscript, selectedText]);

  // editing

  const tapDirty = useMemo(
    () =>
      Object.keys(tapEdits).length > 0 ||
      (title && title !== titleCache.current) ||
      JSON.stringify(textpathOptions) !== tapDataCache.current ||
      (markupDataCache.current && JSON.stringify(markupData) !== markupDataCache.current),
    [markupData, tapEdits, textpathOptions, title]
  );

  useEffect(() => {
    async function fetchBackgrounds() {
      // fetch backgrounds
      const bgs = await AppBackService.getRelatedBackgrounds(activeBackground);
      if (bgs) {
        setBackgrounds(
          bgs.reduce((obj, item) => {
            obj[item.id] = item;
            return obj;
          }, {})
        );
      }
    }
    if (editingTapestry && !backgroundsRef.current) {
      fetchBackgrounds();
    }
  }, [activeBackground, editingTapestry]);

  useEffect(() => {
    async function fetchLayoutData() {
      const resp = await AppBackService.getLayout(activeLayout);
      if (resp) {
        setLayoutData(resp);
      }
    }
    if (editingTapestry && !layoutDataRef.current) {
      fetchLayoutData();
    }
  }, [activeLayout, editingTapestry]);

  const changeBackground = useCallback(
    async (backgroundID) => {
      if (backgrounds && backgrounds[backgroundID]) {
        // update the SVG background
        const imageEl = tapestryContainer.current?.querySelector("image.background-image");
        if (imageEl) {
          imageEl.setAttribute("href", backgrounds[backgroundID].imageUrl);
        }
        setActiveBackground(backgroundID);

        // update font colors
        // update title
        const title = tapestryContainer.current?.querySelector(".tap-title tspan");
        if (title) {
          title.setAttribute(
            "fill",
            layoutData?.title?.color || backgrounds[backgroundID].colors?.primary || "#000000"
          );
        }
        // update text elements associated with specific slots from this layout
        if (layoutData?.slots) {
          for (const slot of layoutData.slots) {
            const textContainer = tapestryContainer.current?.querySelector("#phrase-" + slot.id);
            const text = textContainer?.querySelectorAll("tspan, textPath");
            if (text) {
              text.forEach((t) => {
                t.setAttribute(
                  "fill",
                  (backgrounds[backgroundID].colors &&
                    backgrounds[backgroundID].colors[slot.textpath_data?.color || "primary"]) ||
                    "#000000"
                );
              });
            }
          }
        }
        // update text elements within symbols
        const symbolTexts = tapestryContainer.current?.querySelectorAll(".tapestry-symbol textPath");
        if (symbolTexts) {
          symbolTexts.forEach((t) => {
            t.setAttribute("fill", backgrounds[backgroundID].colors?.primary || "#000000");
          });
        }

        setTapEdits((tapEdits) => ({ ...tapEdits, background_id: backgroundID }));
      }
    },
    [backgrounds, layoutData]
  );

  const updatePhrase = useCallback(async (eventID, index) => {
    // update which phrase option is selected
    setTextpathOptions((textpathOptions) => {
      const textpathOptionsCopy = { ...textpathOptions };
      textpathOptionsCopy[activeLayoutRef.current][eventID].options.forEach((tpo, i) => {
        tpo.selected = i === index;
      });
      textpathOptionsRef.current = textpathOptionsCopy;
      return textpathOptionsCopy;
    });
    const selectedPhrase = textpathOptionsRef.current[activeLayoutRef.current][eventID].options[index];
    setUpdatedPhrase({ eventID, selectedPhrase });
    setPhraseOptions(null);

    setTapEdits((tapEdits) => {
      const tapEditsCopy = { ...tapEdits };
      tapEditsCopy.phrase_edits = { ...tapEditsCopy.phrase_edits, [eventID]: selectedPhrase.textContent };
      return tapEditsCopy;
    });
  }, []);

  const updateTitle = useCallback(
    async (newTitle) => {
      if (newTitle !== tapestryData.title) {
        setTapestryData((tapestryData) => {
          const dataCopy = { ...tapestryData };
          dataCopy.title = newTitle;
          return dataCopy;
        });
        // update the SVG title
        const dateRegex = /\s\d{1,2}\/\d{1,2}\/\d{2,4}$/;
        const tspan = tapestryContainer.current?.querySelector(".tap-title tspan");
        if (tspan) {
          const titleMatch = tspan.textContent.match(dateRegex);
          tspan.textContent = (newTitle + ((titleMatch && titleMatch[0]) || "")).toUpperCase();
        } else {
          // search for title without .tap-title class
          for (const rootChild of tapestryContainer.current.querySelector("svg").childNodes) {
            const childNodes = [...rootChild.childNodes];
            if (rootChild.nodeName === "g" && childNodes.every((child) => child.nodeName === "text")) {
              const titleNode = childNodes.find((child) => dateRegex.test(child.querySelector("tspan")?.textContent));
              if (titleNode) {
                const tspan = titleNode.querySelector("tspan");
                const titleMatch = tspan.textContent.match(dateRegex);
                tspan.textContent = (newTitle + ((titleMatch && titleMatch[0]) || "")).toUpperCase();
              }
            }
          }
        }

        setTapEdits((tapEdits) => ({ ...tapEdits, title: newTitle }));
      }
    },
    [tapestryData]
  );

  const saveTap = useCallback(() => {
    async function saveTapData() {
      const resp = await AppBackService.updateUserTap(userInfo.username, tapId, {
        tap_edits: { ...tapEdits, color: (backgrounds && backgrounds[activeBackground]) || "#000000" },
      });
      if (resp) {
        showSnackbar("Tapestry is saving. Please allow up to a minute for the update!", "success");
        setTapEdits({});
        markupDataCache.current = JSON.stringify(markupData);
        titleCache.current = title;
        tapDataCache.current = JSON.stringify(textpathOptions);
      } else {
        showSnackbar("Error saving Tapestry", "error");
      }
    }
    saveTapData();
  }, [markupData, showSnackbar, tapEdits, tapId, textpathOptions, title, userInfo, backgrounds, activeBackground]);

  const updateMarkupData = useCallback((newMarkupData) => {
    setMarkupData(newMarkupData);
    setTapEdits((tapEdits) => ({ ...tapEdits, markups: newMarkupData }));
  }, []);

  // drag markups from edit panel to tapestry

  const dummyMarkupStyle = useRef();
  const markupOffset = useRef({ x: 0, y: 0 });
  const dragMarkup = useGesture({
    onDragStart: ({ event, xy: [x, y], args }) => {
      const bbox = event.target.getBoundingClientRect();
      markupOffset.current.x = bbox.x + bbox.width / 2 - x;
      markupOffset.current.y = bbox.y + bbox.height / 2 - y;
      setDraggingMarkup(args[0]);
      if (dummyMarkupStyle.current) {
        dummyMarkupStyle.current.style.left = x + markupOffset.current.x + "px";
        dummyMarkupStyle.current.style.top = y + markupOffset.current.y + "px";
      }
    },
    onDrag: ({ xy: [x, y] }) => {
      if (dummyMarkupStyle.current) {
        dummyMarkupStyle.current.style.left = x + markupOffset.current.x + "px";
        dummyMarkupStyle.current.style.top = y + markupOffset.current.y + "px";
      }
    },
    onDragEnd: ({ event, xy: [x, y] }) => {
      const tapDimensions = getTapestryDimensions();
      const tapestryBBox = tapDimensions.tapestryBBox;
      const tapScale = tapDimensions.scale;
      const tapX = x - tapestryBBox.left + markupOffset.current.x;
      const tapY = y - tapestryBBox.top + markupOffset.current.y;
      const el = event.target;
      if (
        tapX - el.clientWidth / 4 > 0 &&
        tapX + el.clientWidth / 4 < tapestryBBox?.width &&
        tapY - el.clientHeight / 4 > 0 &&
        tapY + el.clientHeight / 4 < tapestryBBox?.height
      ) {
        // add markup to markup data
        const markupGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
        markupGroup.innerHTML = draggingMarkup?.svg;
        const markupSvg = markupGroup.querySelector("svg");
        const viewBox = markupSvg.viewBox.baseVal;
        const scale = (defaultMarkupHeight / viewBox.height) * tapScale;
        const newMarkupData = [
          ...markupData,
          {
            id: draggingMarkup.id,
            x: tapX * tapScale - viewBox.width * 0.5,
            y: tapY * tapScale - viewBox.height * 0.5,
            xScale: scale,
            yScale: scale,
            rotate: 0,
            dimensions: {
              width: viewBox.width,
              height: viewBox.height,
            },
          },
        ];
        updateMarkupData(newMarkupData);
      }
      setDraggingMarkup(null);
    },
  });

  // elements

  const transcript = useMemo(
    () => (
      <Box className="side-panel-container" sx={styles.transcriptStyle} ref={transcriptContainerRef}>
        <Typography sx={styles.transcriptHead} className="transcript-heading">
          Transcript
        </Typography>
        <DocumentText docContent={doc.filteredContent} selectedText={selectedText} />
      </Box>
    ),
    [doc, selectedText]
  );

  const markupsEl = useMemo(
    () => (
      <Box className="side-panel-container" sx={styles.markupsStyle}>
        {markups.map((markup) => (
          <img
            key={markup.id}
            src={`https://vt-markups.s3.us-west-2.amazonaws.com/${markup.id}` + ".svg"} // for some reason my IDE breaks if I don't concat here 🤯
            {...dragMarkup(markup)}
            className={classNames("markup-option", { dragging: draggingMarkup })}
            alt={markup.name}
            draggable="false"
          />
        ))}
      </Box>
    ),
    [dragMarkup, draggingMarkup, markups]
  );

  const phraseOptionsEl = useMemo(
    () =>
      phraseOptions ? (
        <Box sx={styles.phraseOptionsStyle} className="phrase-select">
          <Box sx={styles.phraseSelHeader} className="phrase-select-header">
            <Typography sx={styles.phraseSelectStyle} variant="h3">
              Select phrase
            </Typography>
            <CloseIcon sx={styles.closeIcon} onClick={hidePhraseOptions} />
          </Box>
          <Box sx={styles.phraseOptionsList} className="phrase-options">
            {isSelectorValid("#phrase-" + phraseOptions.eventID) ? (
              phraseOptions.options.slice(0, NUM_PHRASE_OPTIONS).map((phraseOption, i) => (
                <Box
                  key={uuid()}
                  sx={styles.phraseOptionsListItem}
                  onClick={() => updatePhrase(phraseOptions.eventID, i)}
                  className={classNames("phrase-option", { selected: phraseOption.selected })}>
                  {phraseOption.textContent}
                </Box>
              ))
            ) : (
              <Box sx={styles.noPhraseOptions}>No alternative phrases found</Box>
            )}
          </Box>
        </Box>
      ) : null,
    [hidePhraseOptions, phraseOptions, updatePhrase]
  );

  const tapestry = useMemo(
    () => (
      <Box
        className={classNames("edit-tapestry", {
          "un-editable": !editingTapestry && !searchingPhrases,
          "editing-phrases": editingTapestry,
          "searching-phrases": searchingPhrases,
        })}
        sx={{ ...styles.tapestryMainPage, paddingBottom: editingTapestry ? "20px" : "53px" }}>
        {!loading && (
          <Box sx={styles.tapestryHeader}>
            <Box sx={styles.tapestryTitle} className="tapestry-title">
              {title && (
                <InlineEdit
                  value={title}
                  setValue={setTitle}
                  submit={updateTitle}
                  textStyle={styles.titleTextStyle}
                  enterSubmit
                />
              )}
              {tapestryData.meetingDate && (
                <Typography sx={styles.tapestryDataStyle}>
                  {dayjs(
                    isNaN(+tapestryData.meetingDate) ? tapestryData.meetingDate : +tapestryData.meetingDate
                  ).format("l")}
                </Typography>
              )}
            </Box>
            <Box sx={styles.tapestrySubtitle} className="tapestry-subtitle">
              {tapDirty && (
                <Box sx={styles.tapActionButtonContainer}>
                  <Button size="medium" sx={styles.saveTapButton} onClick={saveTap}>
                    Save Changes
                  </Button>
                </Box>
              )}
              <Box className="tapestry-actions" sx={styles.tapestryActionMainStyle}>
                <Box
                  className="tapestry-action"
                  sx={{ ...styles.tapestryActionStyle, marginLeft: "0" }}
                  onClick={() => {
                    setSearchingPhrases(false);
                    setEditingTapestry((editingTapestry) => !editingTapestry);
                  }}>
                  <img
                    style={{ ...styles.alignMiddleStyle, height: 12 }}
                    className="pointer-icon"
                    src={pointerIcon}
                    alt=""
                    draggable="false"
                  />
                  <Typography sx={{ color: editingTapestry ? primaryGreen : undefined }}>Edit Tapestry</Typography>
                </Box>
                {doc.filteredContent && (
                  <Box
                    className="tapestry-action"
                    sx={styles.tapestryActionStyle}
                    onClick={() => {
                      setEditingTapestry(false);
                      setSearchingPhrases((searchingPhrases) => !searchingPhrases);
                    }}>
                    <ChatIcon sx={{ ...styles.alignMiddleStyle, color: primaryGreen }} fontSize="24px" />
                    <Typography sx={{ color: searchingPhrases ? primaryGreen : undefined }}>View transcript</Typography>
                  </Box>
                )}
                <Box className="tapestry-action" sx={styles.tapestryActionStyle} onClick={deleteTap}>
                  <CancelIcon sx={{ ...styles.alignMiddleStyle, color: primaryGreen }} fontSize="24px" />
                  <Typography sx={{ color: searchingPhrases ? primaryGreen : undefined }}>Delete Tapestry</Typography>
                </Box>
                <Box className="tapestry-action" sx={styles.tapestryActionStyle} onClick={handleModalOpen}>
                  <IosShareIcon sx={{ ...styles.alignMiddleStyle, color: primaryGreen }} fontSize="24px" />
                  <Typography sx={{ color: searchingPhrases ? primaryGreen : undefined }}>Share Tapestry</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <ViewTranscriptMedia className="edit-tapestry-container">
          <Box
            sx={{
              ...styles.tapestryContainerStyles,
              height: (searchingPhrases || editingTapestry) && window.innerWidth > breakpoint ? tapestryHeight : "auto",
            }}
            className={classNames("edit-tapestry-container", {
              "view-transcript": searchingPhrases || editingTapestry,
            })}
            ref={tapestryContainer}>
            {!svg[activeLayout] || loading ? (
              <CircularProgress size={60} sx={styles.circularProgressStyle} />
            ) : (
              <TapestryView
                svg={svg[activeLayout]}
                updatedPhrase={updatedPhrase}
                width={(searchingPhrases || editingTapestry) && window.innerWidth > breakpoint ? 0.75 : 1}
                markupData={markupData}
                setMarkupData={updateMarkupData}
                editingTapestry={editingTapestry}
                colors={backgrounds && backgrounds[activeBackground]?.colors}
              />
            )}
            {searchingPhrases && doc.filteredContent && transcript}
            {editingTapestry && markupsEl}
            {phraseOptionsEl}
          </Box>
        </ViewTranscriptMedia>
      </Box>
    ),
    [
      editingTapestry,
      searchingPhrases,
      loading,
      title,
      updateTitle,
      tapestryData,
      tapDirty,
      saveTap,
      doc,
      deleteTap,
      handleModalOpen,
      tapestryHeight,
      svg,
      activeLayout,
      updatedPhrase,
      markupData,
      updateMarkupData,
      backgrounds,
      activeBackground,
      transcript,
      markupsEl,
      phraseOptionsEl,
    ]
  );

  const panelSkeleton = useMemo(
    () =>
      Array.from(new Array(3)).map((_d, i) => (
        <Skeleton key={i} variant="rectangular" width={190} height={120} sx={styles.thumbnailStyle} />
      )),
    []
  );

  // alternative backgrounds and layouts
  const editPanelElement = useMemo(
    () => (
      <Box className="edit-panel" sx={styles.editPanelStyles}>
        <Box sx={styles.editPanelStyle}>
          {backgrounds?.length && <Typography sx={styles.editPanelHeaderStyle}>Alternative Backgrounds</Typography>}
          <Box sx={styles.editPanel}>
            {backgrounds ? (
              backgrounds.error ? (
                <ErrorIcon />
              ) : (
                Object.keys(backgrounds)
                  .filter((bgId) => bgId !== activeBackground)
                  .map((bgId) => (
                    <img
                      className="edit-panel-option"
                      key={bgId}
                      style={{ ...styles.thumbnailStyle, cursor: "pointer" }}
                      src={backgrounds[bgId].imageUrl}
                      alt={backgrounds[bgId].description}
                      onClick={() => changeBackground(bgId)}
                      draggable="false"
                    />
                  ))
              )
            ) : (
              panelSkeleton
            )}
          </Box>
        </Box>
      </Box>
    ),
    [activeBackground, backgrounds, changeBackground, panelSkeleton]
  );

  // TODO: we should have a custom error handling message component
  const errorEl = useMemo(
    () => (
      <Box className="error" sx={styles.errorStyle}>
        <ErrorIcon />
      </Box>
    ),
    []
  );

  if (!tapId || redirect) {
    return <Navigate to="/tapestries" />;
  }
  if (error) {
    return errorEl;
  }
  return (
    <Box sx={styles.detailPageStyle} id="detail-page-container">
      {tapestry}
      {editingTapestry && editPanelElement}
      {deleteTapConfirm}
      {snackbarEl}
      <ShareModal open={modalOpen} handleClose={handleModalClose} tapShareUrl={imageURI} title={tapestryData.title} />
      <img
        style={{
          ...styles.dummyMarkupStyle,
          display: draggingMarkup ? "block" : "none",
        }}
        ref={dummyMarkupStyle}
        src={`https://vt-markups.s3.us-west-2.amazonaws.com/${draggingMarkup?.id}` + ".svg"} // for some reason my IDE breaks if I don't concat here 🤯
        alt="Tapestry Markup"
        draggable="false"
      />
    </Box>
  );
};

function phrasesData(textpathOptions) {
  const data = {};
  for (const tpo of Object.values(textpathOptions)) {
    for (const option of tpo.options) {
      if (option.indexes) {
        data[option.textContent] = option.indexes;
      }
    }
  }
  return data;
}

function isSelectorValid(selector) {
  try {
    document.querySelector(selector);
    return true;
  } catch (e) {
    return false;
  }
}

export default DetailPage;
