export const colors = {
  primaryGreen: "#169697",
  secondaryGreen: "#009698", // buttons, share icons , bullets color
  thickGreen: "#006062", // hover on create tapestry button
  white: "#ffffff",
  lightBlack: "#051817", // text color
  grey: "#666666", // cross icon
  darkGrey: "#444444", // select phrase text
  borderGrey: "#999999",
  lightGrey: "#dadde9",
  selectedColor: "#c0e4e5", // select phrase background color
  selectionPhraseColor: "#d6f0f1", // selection phrase background color
  red: "#df380f", // hover on closeIcon
  lightBlue: "#F1F9FB", // background color for accordin
  dropZoneHover: "#e6f4f4",
  noMeetingTextColor: "#688987",
  blue: "#1685d3", // Button in settings dialog
  lightRed: "#f1d0d0", // notification card
  tapError: "#cccccc",
  navBarBlue: "#455c70",
  errorTextColor: "red",
  yellow: "#ffff00",
  changePwdBtn: "#5bbaf9",
  black: "#000000",
  green: "#21b5b6",
  gradientYellow: "#DAF58D",
  gradientBlue: "#89E6E8",
  gradientGreen: "#00A7BC",
  blockOrange: "#ff8521",
  highlightColor: "#9cc038",
  darkRed: "#ed1c24",
  footerColor: "#333",
};
