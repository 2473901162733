import { useState, useMemo, useEffect, useContext, useCallback } from "react";
import AppBackService from "../services/appback";
import { RootContext } from "../context/root-provider";
import { Navigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Box, Typography, Skeleton, Button } from "@mui/material";
import Taps from "../components/Taps";
import CreateTapModal from "../components/CreateTapModal";
import ShareModal from "../components/ShareModal";
import { useSnackbar } from "../components/AlertNotification";
import { useDocumentActions, useTapActions } from "../hooks/hooks";
import ErrorIcon from "@mui/icons-material/Error";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { colors } from "../styles/colors";
import { commonStyles } from "../styles/commonStyles";

const styles = {
  container: {
    padding: "32px 64px",
    marginBottom: "64px",
    textAlign: "left",
  },
  header: {
    color: colors.lightBlack,
    fontWeight: 700,
    fontSize: "36px",
    marginBottom: "26px",
  },
  actions: {
    display: "flex",
    marginTop: "10px",
  },
  actionButton: {
    color: colors.secondaryGreen,
    marginRight: "10px",
    textTransform: "none",
    fontWeight: 600,
  },
  skeleton: {
    marginBottom: "20px",
  },
  docTitle: {
    marginRight: "12px",
    fontWeight: 700,
    fontSize: "24px",
    color: colors.lightBlack,
  },
  docDate: {
    fontSize: "12px",
    color: colors.lightBlack,
    alignSelf: "flex-end",
    lineHeight: "27px",
  },
  taps: {
    marginTop: "10px",
    display: "flex",
    boxSizing: "border-box",
    overflowX: "auto",
  },
  docContent: {
    marginTop: "20px",
    padding: "32px",
    backgroundColor: colors.lightBlue,
    fontSize: "16px",
    lineHeight: "24px",
    whiteSpace: "pre-line",
  },
  docSkeletonStyles: { marginBottom: "20px", width: "100%", maxWidth: "calc(100vw - 128px)", boxSizing: "border-box" },
};

const DocumentPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [doc, setDoc] = useState([]);
  const [createTapModalOpen, setCreateTapModalOpen] = useState(false);
  const [redirect, setRedirect] = useState(null);

  const { userInfo, meetingsSynced, loggingIn, setSelectedTab } = useContext(RootContext);
  const { docId } = useParams();
  const { showSnackbar, snackbarEl } = useSnackbar();

  const [shareTap, setShareTap] = useState(null);
  const handleShareModalClose = useCallback(() => {
    setShareTap(null);
  }, []);

  useEffect(() => {
    setSelectedTab("documents");
  }, [setSelectedTab]);

  // document action handlers

  const updateTitle = useCallback(
    (title) => {
      if (!title) {
        showSnackbar("Unable to update title", "error");
      } else {
        setDoc((doc) => ({ ...doc, title }));
        showSnackbar("Document title updated", "success");
      }
    },
    [showSnackbar]
  );

  const resolveDelete = useCallback(
    (docId) => {
      if (!docId) {
        showSnackbar("Unable to delete Document", "error");
      } else {
        setRedirect(true);
      }
    },
    [showSnackbar]
  );

  const { setDeleting, deleteConfirm, setEditingTitle, editTitleDialog } = useDocumentActions(
    updateTitle,
    resolveDelete
  );

  const resolveTapDelete = useCallback(
    (tapId) => {
      if (!tapId) {
        showSnackbar("Unable to delete Tapestry", "error");
      } else {
        setDoc((doc) => {
          const docCopy = { ...doc };
          const tapIndex = docCopy.taps.findIndex((tap) => tap.tapId === tapId);
          if (tapIndex >= 0) {
            docCopy.taps.splice(tapIndex, 1);
          }
          return docCopy;
        });
        showSnackbar("Tapestry deleted", "success");
      }
    },
    [showSnackbar]
  );

  const { setDeletingTap, deleteTapConfirm } = useTapActions(resolveTapDelete);

  const deleteTap = useCallback(
    (tapId, title) => {
      setDeletingTap({ title, tapId });
    },
    [setDeletingTap]
  );

  // initiate document actions

  const editTitle = useCallback(() => {
    setEditingTitle({ title: doc.title, docId });
  }, [doc, docId, setEditingTitle]);

  const deleteDoc = useCallback(() => {
    setDeleting({ title: doc.title, docId });
  }, [doc, docId, setDeleting]);

  const fetchDoc = useCallback(
    async (showLoading = true) => {
      if (userInfo?.username) {
        if (showLoading) {
          setLoading(true);
        }
        const data = await AppBackService.getUserDoc(userInfo.username, docId);
        if (!data || data.error) {
          setError(true);
        } else {
          if (data.docs?.length) {
            data.docs.forEach((doc) => {
              if (doc.created) {
                doc.created = dayjs(isNaN(+doc.created) ? doc.created : +doc.created);
              }
            });
            setDoc(data.docs[0]);
          }
        }
        if (showLoading) {
          setLoading(false);
        }
      }
    },
    [docId, userInfo]
  );

  // init, fetch docs
  useEffect(() => {
    if (userInfo?.username && meetingsSynced && !loggingIn) {
      fetchDoc();
    }
  }, [fetchDoc, loggingIn, meetingsSynced, userInfo]);

  const docSkeleton = useMemo(
    () => (
      <Box sx={styles.docSkeletonStyles}>
        <Skeleton variant="rectangular" width="100%" height={62} sx={styles.skeleton} />
        <Skeleton variant="rectangular" width="100%" height={100} sx={styles.skeleton} />
        <Skeleton variant="rectangular" width="100%" height={400} sx={styles.skeleton} />
      </Box>
    ),
    []
  );

  const errorEl = useMemo(
    () => (
      <Box className="error" style={styles.error}>
        <ErrorIcon />
      </Box>
    ),
    []
  );

  if (!docId || redirect) {
    return <Navigate to="/documents" />;
  }
  if (error) {
    return errorEl;
  }
  return (
    <Box sx={styles.container}>
      {loading || !doc ? (
        docSkeleton
      ) : (
        <Box sx={styles.doc}>
          <Box sx={styles.header}>
            <Typography sx={styles.docTitle}>{doc.title}</Typography>
            {doc.created && <Typography sx={styles.docDate}>{doc.created.format("llll")}</Typography>}
            <Box sx={styles.actions}>
              <Button sx={styles.actionButton} size="medium" startIcon={<EditIcon />} onClick={editTitle}>
                Edit title
              </Button>
              <Button
                sx={{
                  ...styles.actionButton,
                  "&:hover": {
                    color: colors.red,
                  },
                }}
                size="medium"
                startIcon={<CancelIcon />}
                onClick={deleteDoc}>
                Delete
              </Button>
              <Button
                variant="contained"
                sx={commonStyles.createTapBtn}
                size="medium"
                onClick={() => {
                  setCreateTapModalOpen(true);
                }}>
                Create Tapestry
              </Button>
            </Box>
          </Box>
          {doc.taps && <Taps taps={doc.taps} deleteTap={deleteTap} />}
          <Box sx={styles.docContent}>{doc.content}</Box>
          {deleteConfirm}
          {editTitleDialog}
          {deleteTapConfirm}
          {snackbarEl}
          <CreateTapModal
            open={createTapModalOpen}
            setOpen={setCreateTapModalOpen}
            doc={doc}
            refreshCallback={fetchDoc}
            showSnackbar={showSnackbar}
          />
          <ShareModal
            open={!!shareTap}
            handleClose={handleShareModalClose}
            tapShareUrl={shareTap?.url}
            title={shareTap?.title}
          />
        </Box>
      )}
    </Box>
  );
};

export default DocumentPage;
