import { useContext, useCallback, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import AppBackService from "../services/appback";
import { RootContext } from "../context/root-provider";
import useSelectLayout from "../hooks/useSelectLayout";

const CreateTapModal = ({ open, setOpen, doc, refreshCallback, showSnackbar }) => {
  const { userInfo, notifInterval } = useContext(RootContext);
  const createTapRef = useRef();

  const { error, selectedLayout, selectedBackground, layoutSelector } = useSelectLayout(doc, createTapRef);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const createTap = useCallback(async () => {
    if (doc) {
      const resp = await AppBackService.createUserTap(userInfo?.username, {
        doc_id: doc.docId,
        layout_id: selectedLayout,
        background_id: selectedBackground,
      });

      if (resp && !resp.error) {
        showSnackbar("Creating Tapestry...", "info");
      } else {
        showSnackbar("Error creating Tapestry", "error");
      }
      // start notification polling
      // 10 seconds * 6 intervals = 1 minute for tapestry to be created
      notifInterval(refreshCallback, showSnackbar, 10000, 6, false);
      setOpen(false);
    }
  }, [doc, notifInterval, refreshCallback, selectedBackground, selectedLayout, setOpen, showSnackbar, userInfo]);

  useEffect(() => {
    createTapRef.current = createTap;
  }, [createTap]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={closeModal}>
      <DialogTitle>Create a Tapestry</DialogTitle>
      <DialogContent>{layoutSelector}</DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button disabled={error} onClick={createTap}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
CreateTapModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  doc: PropTypes.object,
  refreshCallback: PropTypes.func,
  showSnackbar: PropTypes.func,
};

export default CreateTapModal;
