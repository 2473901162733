import { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography, Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IosShareIcon from "@mui/icons-material/IosShare";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Link } from "react-router-dom";
import { useTapActions } from "../hooks/hooks";
import CancelIcon from "@mui/icons-material/Cancel";
import symbolLoader from "../assets/symbol_circular_progress.png";
import ShareModal from "./ShareModal";
import { colors } from "../styles/colors";
import { commonStyles } from "../styles/commonStyles";

const CARD_WIDTH = "330px";

const cardTopStyles = {
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  borderRadius: "4px 4px 0 0",
};
const styles = {
  tapMaingrid: {
    width: CARD_WIDTH,
    borderRadius: "4px",
  },
  tapContainer: {
    width: "100%",
    background: colors.lightBlue,
    boxShadow: "0px 2px 4px 1px rgba(5, 24, 23, 0.24)",
    borderRadius: "4px",
    padding: "0",
    boxSizing: "border-box",
    position: "relative",
    "& .deleteTapestry": {
      display: "none",
    },
    "&:hover": {
      "& .deleteTapestry": {
        display: "block !important",
      },
    },
  },
  imageBoxStyle: {
    width: "100%",
    borderRadius: "4px 4px 0 0",
  },
  imgStyle: {
    width: 99,
    marginBottom: 14,
  },
  creatingTapStyle: {
    fontSize: "20px",
    fontWeight: "700",
    textAlign: "center",
    color: colors.lightBlack,
  },
  createTapTextGrid: {
    height: "148px",
    width: "100%",
    boxSizing: "border-box",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
  },
  titleStyle: {
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "700",
    textAlign: "left",
    color: colors.lightBlack,
    marginBottom: "2px",
  },
  linkStyle: { textDecoration: "none" },
  dateStyle: { fontSize: "16px", lineHeight: "24px", textAlign: "left", color: colors.lightBlack },
  viewShareBtnStyles: { position: "absolute", bottom: 0 },
  viewShareBtnBox: {
    flexGrow: 1,
    position: "relative",
    ".MuiButton-startIcon": {
      marginTop: "-1px",
    },
  },
};

/**
 * Represents a tapestry card.
 *
 * @constructor
 * @param {string} tapestryID -  ID number of the card
 * @param {string} title - Title of the card
 * @param {string} date - Date of tapestry creation
 * @param {string} thumbnail - Tapestry thumbnail image
 * @param {string} state - One of "generated" or "generating"
 */

const TapestryCard = ({ tapId, title, date, thumbnail, state, resolveDelete }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);
  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const tapestryLink = useMemo(() => "/tapestry/" + tapId, [tapId]);
  const imageURI = useMemo(() => (tapId ? `https://taps.tapestryai.com/${tapId}.png` : ""), [tapId]);

  const { setDeletingTap, deleteTapConfirm } = useTapActions(resolveDelete);

  const deleteTap = useCallback(
    (e) => {
      e.stopPropagation();
      setDeletingTap({ title, tapId });
    },
    [setDeletingTap, tapId, title]
  );

  const res = useMemo(
    () => (
      <Grid item sx={styles.tapMaingrid}>
        <Box className="tap-container" sx={styles.tapContainer}>
          <Box sx={{ ...styles.imageBoxStyle, height: "192px" }}>
            {state === "error" ? (
              <Box sx={{ backgroundColor: colors.tapError, ...cardTopStyles }}>
                <ReportProblemIcon color="error" fontSize="large" />
              </Box>
            ) : state === "generated" ? (
              <Link to={tapestryLink}>
                <img style={styles.imageBoxStyle} src={imageUrl(thumbnail)} alt={"tapestry image for " + title} />
              </Link>
            ) : (
              <Box
                sx={{
                  background: `linear-gradient(180deg, ${colors.gradientYellow} 0%, ${colors.gradientBlue} 100%);`,
                  ...cardTopStyles,
                }}>
                <img style={styles.imgStyle} src={symbolLoader} alt="loading" />
                <Typography sx={styles.creatingTapStyle} variant="h3">
                  Creating Tapestry
                </Typography>
              </Box>
            )}
          </Box>
          <Box sx={styles.createTapTextGrid}>
            <Link to={tapestryLink} style={styles.linkStyle}>
              <Typography sx={styles.titleStyle} variant="h3">
                {title}
              </Typography>
            </Link>
            <Link to={tapestryLink} style={styles.linkStyle}>
              <Typography sx={styles.dateStyle}>{date}</Typography>
            </Link>
            <Box sx={styles.viewShareBtnBox}>
              <Link to={tapestryLink}>
                <Button
                  size="small"
                  startIcon={<VisibilityIcon />}
                  color="secondary"
                  sx={{ ...styles.viewShareBtnStyles, left: 0 }}>
                  View
                </Button>
              </Link>
              <Button
                size="small"
                startIcon={<IosShareIcon />}
                color="secondary"
                sx={{ ...styles.viewShareBtnStyles, right: 0 }}
                onClick={handleModalOpen}>
                Share
              </Button>
              <ShareModal open={modalOpen} handleClose={handleModalClose} tapShareUrl={imageURI} title={title} />
            </Box>
          </Box>
          <Box className="deleteTapestry" sx={commonStyles.tapDeleteContainer}>
            <CancelIcon fontSize="medium" sx={commonStyles.tapDelete} onClick={deleteTap} />
          </Box>
        </Box>
        {deleteTapConfirm}
      </Grid>
    ),
    [
      state,
      tapestryLink,
      thumbnail,
      title,
      date,
      handleModalOpen,
      modalOpen,
      handleModalClose,
      imageURI,
      deleteTap,
      deleteTapConfirm,
    ]
  );

  return res;
};
TapestryCard.propTypes = {
  tapestryID: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  thumbnail: PropTypes.string,
  error: PropTypes.bool,
  resolveDelete: PropTypes.func,
};

function imageUrl(imageName) {
  return "https://taps.tapestryai.com/" + imageName;
}

export default TapestryCard;
