import React from "react";
import { Container, Grid, Button, TextField, Box, Link } from "@mui/material";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import * as yup from "yup";
import { colors } from "../styles/colors";

const styles = {
  centerGridItemStyle: {
    marginTop: "6%",
    borderRadius: "10px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  headerStyle: { fontSize: "24px", color: colors.navBarBlue },
  textStyle: { fontSize: "14px", marginLeft: "50px", marginRight: "50px" },
  emailTextFieldStyle: {
    width: "72%",
    fieldset: { borderColor: colors.secondaryGreen },
    "& fieldset": { borderColor: colors.secondaryGreen },
    "& .MuiOutlinedInputNotchedOutline": {
      borderColor: colors.secondaryGreen,
    },
    backgroundColor: colors.white,
  },
  submitButtonStyle: {
    width: "72%",
    height: "50px",
    backgroundColor: colors.secondaryGreen,
    marginBottom: "10px",
  },
  linkStyle: { color: colors.secondaryGreen, marginTop: "1em" },
  error: {
    color: colors.errorTextColor,
  },
};

const validationSchema = yup.object({
  email_address: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
});

export default function ForgotPassword(props) {
  const formik = useFormik({
    initialValues: {
      email_address: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleForgotPassword(values);
    },
  });

  const [values, setValues] = React.useState({
    error: "",
    ShowVerification: true,
  });

  const handleForgotPassword = (model) => {
    setValues({ ...values });
    Auth.forgotPassword(model.email_address)
      .then((user) => {
        setValues({
          ...values,
          ShowVerification: false,
          email_address: model.email_address,
        });
        // props.history.push('/forgot-password-verification1');
      })
      .catch((error) => {
        setValues({ ...values, error: error.message });
      });
  };
  const onKeyPress = (e) => {
    if (e.which === 13) {
      e.preventDefault();
      formik.handleSubmit();
    }
  };
  return (
    <div>
      {values.ShowVerification && (
        <div>
          <p style={styles.headerStyle}>Forgot your password?</p>
          <p style={styles.textStyle}>Please enter the email address associated with your account</p>
          <p className="error" style={styles.error}>
            {values.error}
          </p>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <Box mt={1} mb={2}>
                <TextField
                  sx={styles.emailTextFieldStyle}
                  variant="outlined"
                  id="email_address"
                  name="email_address"
                  placeholder="Email Address"
                  value={formik.values.email_address}
                  onChange={formik.handleChange}
                  error={formik.touched.email_address && Boolean(formik.errors.email_address)}
                  helperText={formik.touched.email_address && formik.errors.email_address}
                  onKeyPress={onKeyPress}
                />
              </Box>
              <Button type="submit" variant="contained" sx={styles.submitButtonStyle} id="submit_button">
                Submit
              </Button>
            </form>
            <p>
              Already have an account? &nbsp;
              <Link sx={styles.linkStyle} href="/">
                Login
              </Link>
            </p>
          </div>
          <br />
          <br />
        </div>
      )}
      {!values.ShowVerification && (
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={1} sm={2} md={2} lg={3}>
              {" "}
            </Grid>
            <Grid item xs={10} sm={8} md={8} lg={6} sx={styles.centerGridItemStyle}>
              <div>
                <p align="center">
                  We’ve sent you email at {values.email_address} to reset your password.
                  <br /> <br />
                  Please close this tab and click on the link in the email
                </p>
              </div>
            </Grid>
            <Grid item xs={1} sm={2} md={2} lg={3}>
              {" "}
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
}
