import { useMemo } from "react";

const acceptedFlags = [
  {
    attribute: "email",
    values: ["@vtapestry.com", "@techraq.com", "sendo997@gmail.com"],
  },
];

export default function useFeatureFlag(userInfo) {
  const featureAccepted = useMemo(
    () =>
      acceptedFlags.some((flag) => flag.values.some((value) => userInfo?.attributes[flag.attribute].includes(value))),
    [userInfo]
  );

  return featureAccepted;
}
