import { colors } from "./colors";

export const commonStyles = {
  dialogBtnStyle: {
    backgroundColor: colors.blue,
    color: colors.white,
  },

  pageContainer: {
    padding: "32px 64px",
    paddingBottom: "96px",
    display: "flex",
    justifyContent: "center",
  },

  pageHeader: {
    color: colors.lightBlack,
    fontWeight: 700,
    fontSize: "28px",
  },

  signupLoginHeader: {
    fontSize: "36px",
    fontWeight: 700,
    fontFamily: "Nunito, sans-serif",
    marginTop: "10px",
    color: colors.navBarBlue,
  },

  textFieldStyle: {
    width: "72%",
    marginBottom: "0px",
    fieldset: { borderColor: colors.secondaryGreen },
    "& fieldset": { borderColor: colors.secondaryGreen },
    "& .MuiOutlinedInputNotchedOutline": {
      borderColor: colors.secondaryGreen,
    },
    backgroundColor: colors.white,
  },

  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },

  titleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "32px",
  },

  pageActions: {
    display: "flex",
    justifyContent: "flex-end",
  },

  accordionControl: {
    margin: "10px 5px",
    marginLeft: "0",
    textTransform: "none",
  },

  accordionSummary: {
    ".MuiAccordionSummary-content": {
      display: "flex",
      alignItems: "center",
    },
  },

  bullet: {
    width: "8px",
    height: "8px",
    background: colors.secondaryGreen,
    borderRadius: "4px",
    marginRight: "12px",
  },

  docTitle: {
    marginRight: "12px",
    fontWeight: 700,
    fontSize: "24px",
    color: colors.lightBlack,
    textDecoration: "none",
  },

  docDate: {
    fontSize: "12px",
    color: colors.lightBlack,
    alignSelf: "flex-end",
    lineHeight: "27px",
    pointerEvents: "none",
    marginRight: "40px",
    textWrap: "nowrap",
  },

  tapDeleteContainer: {
    backgroundColor: colors.white,
    position: "absolute",
    top: "8px",
    right: "8px",
    cursor: "pointer",
    borderRadius: "20px",
    zIndex: 10,
  },

  tapDelete: {
    color: colors.grey,
    display: "block",
    "&:hover": {
      color: colors.red,
    },
  },

  createTapBtn: {
    backgroundColor: colors.secondaryGreen,
    marginRight: "10px",
    textTransform: "none",
    fontWeight: 600,
    pointerEvents: "all",
    "&:hover": {
      background: colors.thickGreen,
    },
    textWrap: "nowrap",
  },

  emptyFilter: {
    color: colors.grey,
    fontWeight: "bold",
    fontSize: "22px",
    textAlign: "center",
    width: "1080px",
  },
};
