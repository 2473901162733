import { useMemo } from "react";
import { Toolbar, Box } from "@mui/material";

const styles = {
  footerStyle: { justifyContent: "center", marginTop: "0px" },
  footerTextStyle: { fontSize: "0.9rem", textAlign: "center" },
};

export default function Footer() {
  const res = useMemo(
    () => (
      <>
        <Toolbar variant="footer" style={styles.footerStyle}>
          <Box sx={styles.footerTextStyle} className="notice">
            © {new Date().getFullYear()} Tapestry AI. All rights reserved
          </Box>
        </Toolbar>
      </>
    ),
    []
  );

  return res;
}
