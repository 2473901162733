import { useState, useCallback, useMemo, useContext, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  InputAdornment,
  Input,
  IconButton,
} from "@mui/material";
import { RootContext } from "../context/root-provider";
import SearchIcon from "@mui/icons-material/Search";
import AppBackService from "../services/appback";
import { colors } from "../styles/colors";

const styles = {
  deleteBtnStyles: {
    color: colors.red,
  },
  submitBtnStyles: {
    color: colors.secondaryGreen,
  },
  iconBtnStyles: {
    marginLeft: "-8px",
  },
  dialogText: {
    marginTop: "24px",
  },
};
function useDeleteConfirm(idKey, service, resolveDelete) {
  const [deleting, setDeleting] = useState(null); // set with object containing docId and title
  const { userInfo } = useContext(RootContext);

  const doDelete = useCallback(
    async (id) => {
      const resp = await AppBackService[service](userInfo?.username, id);
      resolveDelete(!resp || resp.error ? null : id);
    },
    [resolveDelete, service, userInfo?.username]
  );

  const handleDelete = useCallback(
    (id) => {
      if (id) {
        doDelete(id);
      }
      setDeleting(null);
    },
    [doDelete]
  );

  const deleteConfirm = useMemo(
    () =>
      deleting && (
        <Dialog open={!!(deleting[idKey] && deleting.title)} onClose={() => handleDelete(null)}>
          <DialogTitle>Delete Document</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the document "{deleting.title}"?
              <br />
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDelete(null)}>Cancel</Button>
            <Button sx={styles.deleteBtnStyles} onClick={() => handleDelete(deleting[idKey])}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      ),
    [deleting, handleDelete, idKey]
  );

  return { setDeleting, deleteConfirm };
}

export function useDocumentActions(updateTitle, resolveDelete) {
  const [editingTitle, setEditingTitle] = useState(null); // set with object containing docId and title
  const [title, setTitle] = useState("");
  const [titleInvalid, setTitleInvalid] = useState(null);
  const { userInfo } = useContext(RootContext);

  useEffect(() => {
    setTitle(editingTitle?.title || "");
  }, [editingTitle]);

  const { setDeleting, deleteConfirm } = useDeleteConfirm("docId", "deleteUserDoc", resolveDelete);

  const saveTitle = useCallback(
    async (docId, newTitle) => {
      const resp = await AppBackService.updateUserDoc(userInfo?.username, docId, { title: newTitle });
      updateTitle(!resp || resp.error ? null : newTitle, docId);
    },
    [updateTitle, userInfo]
  );

  const handleTitle = useCallback(
    (docId, newTitle) => {
      if (newTitle) {
        if (!/^[A-Za-z0-9\s'"_@./#&+-]*$/.test(newTitle)) {
          setTitleInvalid("Only letters and numbers allowed");
        } else {
          if (newTitle !== editingTitle.title) {
            saveTitle(docId, newTitle);
          }
          setTitleInvalid(null);
          setEditingTitle(null);
          setTitle("");
        }
      } else if (newTitle === "") {
        setTitleInvalid("Please enter a title");
      } else {
        setTitleInvalid(null);
        setEditingTitle(null);
        setTitle("");
      }
    },
    [editingTitle, saveTitle]
  );

  const editTitleDialog = useMemo(
    () =>
      editingTitle && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={!!(editingTitle.docId && editingTitle.title)}
          onClose={() => handleTitle(null)}>
          <DialogTitle>Edit Document Title</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter a new title for the document "{editingTitle.title}".</DialogContentText>
            <TextField
              error={!!titleInvalid}
              helperText={titleInvalid}
              autoFocus
              margin="dense"
              id="name"
              type="text"
              fullWidth
              variant="standard"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleTitle(null)}>Cancel</Button>
            <Button sx={styles.submitBtnStyles} onClick={() => handleTitle(editingTitle.docId, title)}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      ),
    [editingTitle, handleTitle, title, titleInvalid]
  );

  return { setDeleting, deleteConfirm, setEditingTitle, editTitleDialog };
}

export function useMeetingActions(update, resolveDelete) {
  const [editingMeeting, setEditingMeeting] = useState(null);
  const [title, setTitle] = useState("");
  const [, setTitleInvalid] = useState(null);
  const [purpose, setPurpose] = useState("");
  const { userInfo } = useContext(RootContext);

  const { setDeleting, deleteConfirm } = useDeleteConfirm("meetingId", "deleteUserMeeting", resolveDelete);

  useEffect(() => {
    setTitle(editingMeeting?.title || "");
    setPurpose(editingMeeting?.purpose || "");
  }, [editingMeeting]);

  const saveMeeting = useCallback(
    async (updates, id) => {
      const resp = await AppBackService.updateMeeting(userInfo?.username, id, updates);
      update(!resp || resp.error ? null : updates, id);
    },
    [update, userInfo]
  );

  const handleUpdate = useCallback(
    (updates, id) => {
      if (updates) {
        if (updates.title === "") {
          setTitleInvalid("Please enter a title");
        } else if (!/^[A-Za-z0-9\s'"_@./#&+-]*$/.test(updates.title)) {
          setTitleInvalid("Only letters and numbers allowed");
        } else if (updates.title !== editingMeeting.title || updates.purpose !== editingMeeting.purpose) {
          saveMeeting(updates, id);
        }
      }
      setTitleInvalid(null);
      setEditingMeeting(null);
      setTitle("");
      setPurpose("");
    },
    [editingMeeting, saveMeeting]
  );

  const editMeetingDialog = useMemo(
    () =>
      editingMeeting && (
        <Dialog fullWidth maxWidth="sm" open={!!editingMeeting} onClose={() => handleUpdate(null)}>
          <DialogTitle>Edit Meeting</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>Enter a new title for the meeting "{editingMeeting.title}".</DialogContentText>
            <TextField
              error={!!titleInvalid}
              helperText={titleInvalid}
              autoFocus
              margin="dense"
              id="title"
              type="text"
              fullWidth
              variant="standard"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            /> */}
            <DialogContentText sx={styles.dialogText}>Describe the purpose of the meeting.</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="purpose"
              type="text"
              fullWidth
              multiline
              minRows={3}
              placeholder="Meeting purpose"
              variant="standard"
              value={purpose}
              onChange={(e) => {
                setPurpose(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleUpdate(null)}>Cancel</Button>
            <Button sx={styles.submitBtnStyles} onClick={() => handleUpdate({ title, purpose }, editingMeeting.id)}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      ),
    [editingMeeting, handleUpdate, purpose, title]
  );

  return { setDeleting, deleteConfirm, setEditingMeeting, editMeetingDialog };
}

export function useTapActions(resolveDelete) {
  const [deletingTap, setDeletingTap] = useState(null); // set with object containing tapId and title
  const { userInfo } = useContext(RootContext);

  const deleteTap = useCallback(
    async (tapId) => {
      const resp = await AppBackService.deleteUserTap(userInfo?.username, tapId);
      resolveDelete(!resp || resp.error ? null : tapId);
    },
    [resolveDelete, userInfo]
  );

  const handleDelete = useCallback(
    (tapId) => {
      if (tapId) {
        deleteTap(tapId);
      }
      setDeletingTap(null);
    },
    [deleteTap]
  );

  const deleteTapConfirm = useMemo(
    () =>
      deletingTap && (
        <Dialog open={!!(deletingTap.tapId && deletingTap.title)} onClose={() => handleDelete(null)}>
          <DialogTitle>Delete Tapestry</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the Tapestry "{deletingTap.title}"?
              <br />
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDelete(null)}>Cancel</Button>
            <Button sx={styles.deleteBtnStyles} onClick={() => handleDelete(deletingTap.tapId)}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      ),
    [deletingTap, handleDelete]
  );

  return { setDeletingTap, deleteTapConfirm };
}

export function useFilter(placeholder) {
  const [filter, setFilter] = useState("");
  const filterRef = useRef();
  const [filtering, setFiltering] = useState(false);
  const filterBar = useRef();

  useEffect(() => {
    if (filtering && filterBar.current) {
      filterBar.current.focus();
    }
  }, [filtering]);

  useEffect(() => {
    filterRef.current = filter;
  }, [filter]);

  useEffect(() => {
    function documentClick(e) {
      if (!filterRef.current && !e.target.closest("#filter-input") && !e.target.closest("#filter-icon")) {
        setFiltering(false);
      }
    }
    document.addEventListener("click", documentClick);

    return () => {
      document.removeEventListener("click", documentClick);
    };
  });

  const filterEl = useMemo(
    () =>
      filtering ? (
        <Input
          id="filter-input"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon color="secondary" />
            </InputAdornment>
          }
          placeholder={placeholder}
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          inputRef={filterBar}
        />
      ) : (
        <IconButton id="filter-icon" aria-label="filter" onClick={() => setFiltering(true)} sx={styles.iconBtnStyles}>
          <SearchIcon color="secondary" />
        </IconButton>
      ),
    [filter, filtering, placeholder]
  );

  return { filter, filterEl };
}
