import { useState, useMemo, useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import AppBackService from "../services/appback";
import { RootContext } from "../context/root-provider";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  Button,
  Skeleton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  TextField,
  Grid,
} from "@mui/material";
import Taps from "../components/Taps";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import CreateTapModal from "../components/CreateTapModal";
import { useSnackbar } from "../components/AlertNotification";
import { useDocumentActions, useTapActions, useFilter } from "../hooks/hooks";
import emptyDocsImg from "../assets/blank_docs.png";
import { colors } from "../styles/colors";
import { commonStyles } from "../styles/commonStyles";

const styles = {
  uploadButton: {
    background: colors.secondaryGreen,
    height: "44px",
    color: colors.white,
    textTransform: "none",
    fontWeight: 700,
    fontSize: "16px",
    padding: "0 12px",
    "&:hover": {
      background: colors.thickGreen,
    },
  },
  docTitle: {
    ...commonStyles.docTitle,
    "&:hover": {
      color: colors.secondaryGreen,
    },
  },

  actions: {
    display: "flex",
    alignSelf: "flex-end",
  },
  actionButton: {
    color: colors.secondaryGreen,
    pointerEvents: "all",
  },
  emptyHeader: {
    fontSize: "36px",
    fontWeight: 700,
    margin: "20px 0",
  },
  emptySubheading: {
    fontSize: "24px",
    fontWeight: 700,
    margin: "20px 0 40px 0",
  },
  uploadBtn: {
    position: "absolute",
    right: 8,
    top: 12,
    color: (theme) => theme.palette.grey[500],
  },
  docContainer: {
    padding: "20px",
  },
  attachDocText: {
    marginBottom: "8px",
    fontWeight: "bold",
  },
  uploadIconStyles: {
    color: colors.secondaryGreen,
  },
  enterDocText: {
    marginBottom: "14px",
    fontWeight: "bold",
  },
  docTextField: {
    marginBottom: "10px",
  },
  docSkeltonStyle: {
    marginBottom: "20px",
    width: "870px",
    maxWidth: "calc(100vw - 128px)",
  },
  filterIcon: {
    marginLeft: "16px",
  },
  docsContainer: {
    maxWidth: "1080px",
  },
  accordionStyle: {
    backgroundColor: colors.lightBlue,
    minWidth: "870px",
  },

  emptyDocImage: {
    width: "367px",
  },
  dropZoneStyle: {
    minWidth: "290px",
    boxSizing: "border-box",
    textAlign: "center",
    padding: "20px",
    border: `3px dashed ${colors.secondaryGreen}`,
    cursor: "pointer",
    transition: "background-color 0.2s ease",
    "&:hover": {
      background: colors.dropZoneHover,
    },
    "& p": {
      margin: "8px 0",
      lineHeight: "24px",
    },
  },
  clickCta: {
    color: colors.secondaryGreen,
    textDecoration: "underline",
    fontWeight: 600,
  },
  error: {
    color: colors.red,
  },
};

function docName() {
  return "Document - " + dayjs().format("llll");
}

const DocumentsPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [docs, setDocs] = useState([]);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [uploadDocName, setUploadDocName] = useState(docName());
  const [uploadTextfield, setUploadTextfield] = useState("");
  const [createTapModalOpen, setCreateTapModalOpen] = useState(false);
  const [creatingDoc, setCreatingDoc] = useState();
  const [expandedAccordions, setExpandedAccordions] = useState([]);

  const { userInfo, meetingsSynced, loggingIn, setSelectedTab } = useContext(RootContext);
  const { showSnackbar, snackbarEl } = useSnackbar();
  const { filter, filterEl } = useFilter("Filter Documents");

  useEffect(() => {
    setSelectedTab("documents");
  }, [setSelectedTab]);

  // document action handlers

  const updateTitle = useCallback(
    (title, docId) => {
      if (!title) {
        showSnackbar("Unable to update title", "error");
      } else {
        setDocs((docs) => {
          const docsCopy = [...docs];
          const updatedDoc = docsCopy.find((doc) => doc.docId === docId);
          updatedDoc.title = title;
          return docsCopy;
        });
        showSnackbar("Document title updated", "success");
      }
    },
    [showSnackbar]
  );

  const resolveDelete = useCallback(
    (docId) => {
      if (!docId) {
        showSnackbar("Unable to delete document", "error");
      } else {
        setDocs((docs) => {
          const docsCopy = [...docs];
          const removeIndex = docsCopy.findIndex((doc) => doc.docId === docId);
          docsCopy.splice(removeIndex, 1);
          setExpandedAccordions((expandedAccordions) => {
            const expandedAccordionsCopy = [...expandedAccordions];
            expandedAccordionsCopy.splice(removeIndex, 1);
            return expandedAccordionsCopy;
          });
          return docsCopy;
        });
        showSnackbar("Document deleted", "success");
      }
    },
    [showSnackbar]
  );

  const { setDeleting, deleteConfirm, setEditingTitle, editTitleDialog } = useDocumentActions(
    updateTitle,
    resolveDelete
  );

  const resolveTapDelete = useCallback(
    (tapId) => {
      if (!tapId) {
        showSnackbar("Unable to delete Tapestry", "error");
      } else {
        setDocs((docs) => {
          const docsCopy = [...docs];
          const docIndex = docsCopy.findIndex((doc) => doc.taps.find((tap) => tap.tapId === tapId));
          const tapIndex = docsCopy[docIndex].taps.findIndex((tap) => tap.tapId === tapId);
          if (tapIndex >= 0) {
            docsCopy[docIndex].taps.splice(tapIndex, 1);
            docsCopy[docIndex] = { ...docsCopy[docIndex] };
          }
          return docsCopy;
        });
        showSnackbar("Tapestry deleted", "success");
      }
    },
    [showSnackbar]
  );

  const { setDeletingTap, deleteTapConfirm } = useTapActions(resolveTapDelete);

  const deleteTap = useCallback(
    (tapId, title) => {
      setDeletingTap({ title, tapId });
    },
    [setDeletingTap]
  );

  // initiate document actions

  const editTitle = useCallback(
    (title, docId) => {
      setEditingTitle({ title, docId });
    },
    [setEditingTitle]
  );

  const deleteDoc = useCallback(
    (title, docId) => {
      setDeleting({ title, docId });
    },
    [setDeleting]
  );

  const fetchDocs = useCallback(
    async (showLoading = true) => {
      if (userInfo?.username) {
        if (showLoading) {
          setLoading(true);
        }
        const data = await AppBackService.getUserDocs(userInfo.username);
        if (!data || data.error) {
          setError(true);
        } else {
          if (data.docs?.length) {
            data.docs.forEach((doc) => {
              doc.created = dayjs(isNaN(+doc.created) ? doc.created : +doc.created);
            });
            data.docs.sort((a, b) => (a.created < b.created ? 1 : -1));
            setExpandedAccordions(data.docs.map((doc) => !(!doc.taps?.length && !doc.purpose)));
            setDocs(data.docs);
          }
        }
        if (showLoading) {
          setLoading(false);
        }
      }
    },
    [userInfo]
  );

  // init, fetch docs
  useEffect(() => {
    if (userInfo?.username && meetingsSynced && !loggingIn) {
      fetchDocs();
    }
  }, [fetchDocs, loggingIn, meetingsSynced, userInfo]);

  const openUploadModal = useCallback(() => {
    setUploadModalOpen(true);
  }, []);

  const closeUploadModal = useCallback(() => {
    setUploadModalOpen(false);
  }, []);

  useEffect(() => {
    if (!uploadModalOpen) {
      setUploadDocName(docName());
      setUploadTextfield("");
    }
  }, [uploadModalOpen]);

  const uploadDocuments = useCallback(
    (files) => {
      if (files.length) {
        for (const file of files) {
          const title = file.name.match(/^([^\\]*)\.[^.]+$/)[1] || new Date().toLocaleString();
          const fr = new FileReader();
          fr.onload = (e) => {
            async function postFiles() {
              const resp = await AppBackService.uploadDoc(userInfo.username, {
                title,
                file_name: file.name,
                file_data: window.btoa(e.target.result),
              });
              if (!resp || resp.error) {
                showSnackbar("Unable to upload document", "error");
              } else {
                showSnackbar(`Document ${title} uploaded`, "success");
                // refresh docs which include the new doc
                if (userInfo?.username && meetingsSynced && !loggingIn) {
                  fetchDocs(false);
                }
              }
              closeUploadModal();
            }
            postFiles();
          };
          fr.onerror = () => {
            showSnackbar("Unable to upload document", "error");
          };
          showSnackbar(`Uploading document: ${file.name}`, "info");
          fr.readAsBinaryString(file);
        }
      }
    },
    [closeUploadModal, fetchDocs, loggingIn, meetingsSynced, showSnackbar, userInfo.username]
  );

  const uploadDocumentText = useCallback(async () => {
    const resp = await AppBackService.uploadDoc(userInfo.username, {
      title: uploadDocName,
      content: uploadTextfield,
    });
    if (!resp || resp.error) {
      showSnackbar("Unable to upload document", "error");
    } else {
      showSnackbar(`Document ${uploadDocName} uploaded`, "success");
      // refresh docs which include the new doc
      if (userInfo?.username && meetingsSynced && !loggingIn) {
        fetchDocs(false);
      }
    }
    closeUploadModal();
  }, [closeUploadModal, fetchDocs, loggingIn, meetingsSynced, showSnackbar, uploadDocName, uploadTextfield, userInfo]);

  const expandAll = useCallback(() => {
    setExpandedAccordions(docs.map((doc) => !(!doc.taps?.length && !doc.purpose)));
  }, [docs]);

  const collapseAll = useCallback(() => {
    setExpandedAccordions((expandedAccordions) => expandedAccordions.map(() => false));
  }, []);

  // elements

  const uploadModal = useMemo(
    () => (
      <Dialog fullWidth maxWidth="sm" open={uploadModalOpen} onClose={closeUploadModal}>
        <DialogTitle>
          Upload
          <IconButton aria-label="close" onClick={closeUploadModal} sx={styles.uploadBtn}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box sx={styles.docContainer}>
          <Typography sx={styles.attachDocText}>Attach a document</Typography>
          <Dropzone
            onDrop={(acceptedFiles) => uploadDocuments(acceptedFiles)}
            multiple={false}
            accept={{
              "text/plain": [".txt"],
              "application/rtf": [".rtf"],
              "application/msword": [".doc"],
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            }}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <Grid {...getRootProps()} sx={styles.dropZoneStyle}>
                  <input {...getInputProps()} />
                  <UploadIcon fontSize="large" sx={styles.uploadIconStyles} />
                  <p>
                    Drag and drop here
                    <br />
                    <span className="click-cta" style={styles.clickCta}>
                      or click to browse files
                    </span>
                    <br />
                    Accepted file types: .doc, .docx, .txt, .rtf
                  </p>
                </Grid>
              </section>
            )}
          </Dropzone>
        </Box>
        <Box component="form" sx={styles.docContainer}>
          <Typography sx={styles.enterDocText}>Enter a document</Typography>
          <div>
            <TextField
              id="document-name"
              label="Document name"
              value={uploadDocName}
              onChange={(e) => setUploadDocName(e.target.value)}
              sx={styles.docTextField}
              fullWidth
            />
          </div>
          <div>
            <TextField
              id="document-content"
              label="Document content"
              value={uploadTextfield}
              onChange={(e) => setUploadTextfield(e.target.value)}
              multiline
              placeholder="Copy and paste, or type document here"
              rows={6}
              fullWidth
            />
          </div>
        </Box>
        {uploadTextfield ? (
          <DialogActions>
            <Button onClick={closeUploadModal}>Cancel</Button>
            <Button onClick={uploadDocumentText}>Upload Document</Button>
          </DialogActions>
        ) : null}
      </Dialog>
    ),
    [closeUploadModal, uploadDocName, uploadDocumentText, uploadDocuments, uploadModalOpen, uploadTextfield]
  );

  const docsSkeleton = useMemo(
    () =>
      Array.from(new Array(3)).map((_d, i) => (
        <Box sx={styles.docSkeltonStyle} key={i}>
          <Skeleton variant="rectangular" width="100%" height={62} />
        </Box>
      )),
    []
  );

  const docsEls = useMemo(
    () =>
      docs
        .filter(
          (document) =>
            !filter ||
            document.title.toLowerCase().includes(filter.toLowerCase()) ||
            document.created.format("llll").toLowerCase().includes(filter.toLowerCase())
        )
        .map((doc, i) => (
          <DocumentAccordion
            key={doc.docId}
            doc={doc}
            index={i}
            deleteDoc={deleteDoc}
            editTitle={editTitle}
            setCreateTapModalOpen={setCreateTapModalOpen}
            setCreatingDoc={setCreatingDoc}
            deleteTap={deleteTap}
            expanded={expandedAccordions[i]}
            setExpandedAccordions={setExpandedAccordions}
          />
        )),
    [deleteDoc, deleteTap, docs, editTitle, expandedAccordions, filter]
  );

  const errorEl = useMemo(
    () => (
      <Box sx={styles.error}>
        <ErrorIcon />
      </Box>
    ),
    []
  );

  return error ? (
    errorEl
  ) : (
    <Box sx={{ ...commonStyles.pageContainer, textAlign: docs.length ? "left" : "center" }}>
      {docs.length || loading ? (
        <Box>
          <Box sx={commonStyles.headerContainer}>
            <Box sx={commonStyles.titleContainer}>
              <Typography sx={commonStyles.pageHeader}>Documents</Typography>
              <Box sx={styles.filterIcon}>{filterEl}</Box>
            </Box>
            <Button size="medium" startIcon={<UploadIcon />} sx={styles.uploadButton} onClick={openUploadModal}>
              Upload
            </Button>
          </Box>
          {expandedAccordions.length && docsEls.length ? (
            <Box sx={commonStyles.pageActions}>
              <Button
                sx={commonStyles.accordionControl}
                size="small"
                onClick={expandAll}
                disabled={expandedAccordions.every((a, i) => a || (!docs[i].taps?.length && !docs[i].purpose))}>
                Expand All
              </Button>
              <Button
                sx={commonStyles.accordionControl}
                size="small"
                onClick={collapseAll}
                disabled={expandedAccordions.every((a) => !a)}>
                Collapse All
              </Button>
            </Box>
          ) : null}
          <Box sx={styles.docsContainer}>
            {loading ? (
              docsSkeleton
            ) : docsEls.length ? (
              docsEls
            ) : (
              <Typography sx={commonStyles.emptyFilter}>No documents found</Typography>
            )}
          </Box>
          <CreateTapModal
            open={createTapModalOpen}
            setOpen={setCreateTapModalOpen}
            doc={creatingDoc}
            refreshCallback={fetchDocs}
            showSnackbar={showSnackbar}
          />
        </Box>
      ) : (
        <Box>
          <Typography sx={styles.emptyHeader}>Ready to make visual magic with your words?</Typography>
          <img style={styles.emptyDocImage} src={emptyDocsImg} alt="symbols representing visual magic" />
          <Typography sx={styles.emptySubheading}>Upload a document to create a Tapestry.</Typography>
          <Button size="medium" startIcon={<UploadIcon />} sx={styles.uploadButton} onClick={openUploadModal}>
            Upload Document
          </Button>
        </Box>
      )}
      {uploadModal}
      {deleteConfirm}
      {editTitleDialog}
      {deleteTapConfirm}
      {snackbarEl}
    </Box>
  );
};

const DocumentAccordion = ({
  doc,
  index,
  deleteDoc,
  editTitle,
  setCreateTapModalOpen,
  setCreatingDoc,
  deleteTap,
  expanded,
  setExpandedAccordions,
}) => {
  const handleChange = useCallback(() => {
    setExpandedAccordions((expandedAccordions) => {
      const expandedAccordionsCopy = [...expandedAccordions];
      expandedAccordionsCopy[index] = !expandedAccordionsCopy[index];
      return expandedAccordionsCopy;
    });
  }, [index, setExpandedAccordions]);

  return (
    <Accordion
      sx={{ pointerEvents: !doc.taps.length ? "none" : "all", ...styles.accordionStyle }}
      expanded={expanded}
      onChange={handleChange}>
      <AccordionSummary
        sx={commonStyles.accordionSummary}
        expandIcon={<ExpandMoreIcon sx={{ visibility: !doc.taps.length ? "hidden" : "visible" }} />}
        aria-controls={`doc${index}-content`}
        id={`doc${index}-header`}>
        <Box sx={commonStyles.bullet}></Box>
        <Link to={`/document/${doc.docId}`} style={{ textDecoration: "none", pointerEvents: "all" }}>
          <Typography sx={styles.docTitle}>{doc.title}</Typography>
        </Link>
        <Typography sx={commonStyles.docDate}>{doc.created.format("llll")}</Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box sx={styles.actions}>
          <IconButton
            sx={styles.actionButton}
            title="Edit title"
            aria-label="Edit title"
            onClick={(e) => {
              e.stopPropagation();
              editTitle(doc.title, doc.docId);
            }}>
            <EditIcon />
          </IconButton>
          <IconButton
            sx={{
              ...styles.actionButton,
              marginRight: "10px",
              "&:hover": {
                color: colors.red,
              },
            }}
            title="Delete Document"
            aria-label="Delete Document"
            onClick={(e) => {
              e.stopPropagation();
              deleteDoc(doc.title, doc.docId);
            }}>
            <CancelIcon />
          </IconButton>
          <Button
            variant="contained"
            sx={commonStyles.createTapBtn}
            size="medium"
            onClick={(e) => {
              e.stopPropagation();
              setCreatingDoc(doc);
              setCreateTapModalOpen(true);
            }}>
            Create Tapestry
          </Button>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{doc.taps && <Taps taps={doc.taps} deleteTap={deleteTap} />}</AccordionDetails>
    </Accordion>
  );
};
DocumentAccordion.propTypes = {
  doc: PropTypes.object,
  index: PropTypes.number,
  deleteDoc: PropTypes.func,
  editTitle: PropTypes.func,
  setCreateTapModalOpen: PropTypes.func,
  setCreatingDoc: PropTypes.func,
  deleteTap: PropTypes.func,
  expanded: PropTypes.bool,
  setExpandedAccordions: PropTypes.func,
};

export default DocumentsPage;
