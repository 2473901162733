import { useMemo, useCallback } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { colors } from "../styles/colors";

const styles = {
  boxStyle: { borderBottom: 1, borderColor: "rgba(69, 92, 112, 0.24)", marginBottom: "17px" },
  buttonContainerStyle: { textAlign: "right" },
  closeButtonStyle: { fontSize: 24, cursor: "pointer" },
  ctaButtonStyle: {
    backgroundColor: colors.secondaryGreen,
    color: colors.white,
    "&:hover": { backgroundColor: colors.thickGreen },
    textTransform: "none",
    fontWeight: "bold",
    marginTop: "5px",
  },
  typeTextContainerStyle: { backgroundColor: "rgba(208, 241, 241, 1)", display: "inline-flex" },
  typeTextContainerErrorStyle: { backgroundColor: colors.lightRed, display: "inline-flex" },
  typeTextStyle: { padding: "2px 6px", fontSize: "12px" },
  titleTextStyle: { fontSize: "16px", paddingTop: "6px", fontWeight: 600 },
  dateTextStyle: { fontSize: "12px", paddingTop: "5px" },
  imageStyle: {
    width: "200px",
    marginTop: "8px",
    cursor: "pointer",
  },
};

const NotificationCard = ({ notification, lastNotification, remove }) => {
  const navigate = useNavigate();

  const removeNotif = useCallback(() => {
    remove(notification.notifId);
  }, [notification, remove]);

  const notifData = useMemo(() => {
    if (notification.type === "tapgen" && notification.action === "create") {
      return notification.msg.toLowerCase() === "success"
        ? {
            typeText: "New Tapestry Created",
            msg: notification.msg,
            title: `Tapestry "${notification.tap?.title}" created`,
            date: dayjs(notification.tap.datetime).format("llll"),
            link: "/tapestry/" + notification.tap.tapId,
            thumbnail: notification.tap.thumbnailUrl,
            tapId: notification.tap.tapId,
          }
        : {
            typeText: "Tapestry Error",
            msg: notification.msg,
            title: "Error creating Tapestry",
          };
    }
    return null;
  }, [notification]);

  const cta = useCallback(() => {
    removeNotif();
    if (notifData.link) {
      navigate(notifData.link);
    }
  }, [navigate, notifData, removeNotif]);

  return (
    notifData && (
      <Box
        sx={{
          ...(!lastNotification ? styles.boxStyle : {}),
          paddingBottom: lastNotification ? "7px" : "15px",
          marginTop: "8px",
        }}>
        <Grid container>
          <Grid item xs={11} sm={11} md={11} lg={11}>
            <Box
              sx={
                notifData.msg.toLowerCase() === "success"
                  ? styles.typeTextContainerStyle
                  : styles.typeTextContainerErrorStyle
              }>
              <Typography sx={styles.typeTextStyle}>{notifData.typeText}</Typography>
            </Box>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} sx={styles.buttonContainerStyle}>
            <CloseIcon sx={styles.closeButtonStyle} onClick={removeNotif} />
          </Grid>
        </Grid>
        <Typography sx={styles.titleTextStyle}>{notifData.title}</Typography>
        {notifData.date && <Typography sx={styles.dateTextStyle}>{notifData.date}</Typography>}
        {notifData.thumbnail && (
          <Link to={notifData.link}>
            {/* <img style={{ width: 200 }} src={notifData.thumbnail} alt={notifData.title + " thumbnail"} /> */}
            <img
              className="tap-notif-thumbnail"
              style={styles.imageStyle}
              src={`https://taps.tapestryai.com/${notifData.tapId}_tn.png`}
              alt={notifData.title + " thumbnail"}
              onClick={cta}
              onMouseEnter={(e) => (e.target.style.opacity = 0.75)}
              onMouseLeave={(e) => (e.target.style.opacity = 1)}
            />
          </Link>
        )}
        {notifData.cta && (
          <div style={styles.buttonContainerStyle}>
            <Button sx={styles.ctaButtonStyle} onClick={cta}>
              {notifData.cta}
            </Button>
          </div>
        )}
      </Box>
    )
  );
};
NotificationCard.propTypes = {
  notification: PropTypes.object,
  lastNotification: PropTypes.bool,
  remove: PropTypes.func,
};

export default NotificationCard;
