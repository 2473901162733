import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Box, Typography, Dialog, DialogTitle, IconButton } from "@mui/material";
import AlertNotification from "./AlertNotification";
import LinkIcon from "@mui/icons-material/Link";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import AttachmentIcon from "@mui/icons-material/Attachment";
import DownloadIcon from "@mui/icons-material/Download";
import { colors } from "../styles/colors";

const styles = {
  shareOption: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
    cursor: "pointer",
    color: colors.lightBlack,
    "&:hover p": {
      color: colors.secondaryGreen,
    },
  },
  iconStyle: {
    color: colors.secondaryGreen,
    marginRight: "8px",
  },
  dialogBox: {
    padding: "20px",
    paddingTop: "10px",
  },
  closeIcon: {
    position: "absolute",
    right: 8,
    top: 12,
    color: (theme) => theme.palette.grey[500],
  },
};

/**
 * Pop-up modal to containing options for sharing a tapestry.
 *
 * Leverages Material UI Component Modal.
 *
 * @constructor
 * @param {boolean} open -  Boolean to control whether the modal is open or closed
 * @param {function} handleClose - Function to close the modal
 * @param {string} tapShareUrl - String containing the tapestry URL
 * @param {string} title - String containing the document or meeting title
 */

const ShareModal = ({ open, handleClose, tapShareUrl, title }) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [imageBlob, setImageBlob] = useState(null);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(tapShareUrl);
    setAlertOpen(true);
    handleClose();
  }, [handleClose, tapShareUrl]);

  const emailLink = useMemo(() => `mailto:?subject=Tapestry - ${title}&body=${tapShareUrl}`, [title, tapShareUrl]);

  const downloadImage = useCallback(() => {
    const imageURL = URL.createObjectURL(imageBlob);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = title + ".png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [title, imageBlob]);

  const copyImageToClipboard = useCallback(() => {
    try {
      navigator.clipboard.write([
        new ClipboardItem({
          "image/png": imageBlob,
        }),
      ]);
      setAlertOpen(true);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  }, [handleClose, imageBlob]);

  useEffect(() => {
    async function downloadImage() {
      const image = await fetch(tapShareUrl);
      const blob = await image.blob();
      setImageBlob(blob);
    }
    downloadImage();
  }, [tapShareUrl]);

  const res = useMemo(
    () => (
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            Share
            <IconButton aria-label="close" onClick={handleClose} sx={styles.closeIcon}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Box sx={styles.dialogBox}>
            <Box sx={styles.shareOption} onClick={copyToClipboard}>
              <LinkIcon sx={styles.iconStyle} />
              <Typography>Copy link to clipboard</Typography>
            </Box>
            <a href={emailLink} style={{ textDecoration: "none" }} target="_blank" rel="noreferrer">
              <Box sx={styles.shareOption} onClick={handleClose}>
                <EmailIcon sx={styles.iconStyle} />
                <Typography>Share via email</Typography>
              </Box>
            </a>
            {imageBlob && (
              <Box sx={styles.shareOption} onClick={downloadImage}>
                <DownloadIcon sx={styles.iconStyle} />
                <Typography>Download image</Typography>
              </Box>
            )}
            {imageBlob && (
              <Box sx={{ ...styles.shareOption, marginBottom: 0 }} onClick={copyImageToClipboard}>
                <AttachmentIcon sx={styles.iconStyle} />
                <Typography>Copy image to clipboard</Typography>
              </Box>
            )}
          </Box>
        </Dialog>
        <AlertNotification message="Copied to clipboard" severity="success" open={alertOpen} setOpen={setAlertOpen} />
      </div>
    ),
    [alertOpen, copyImageToClipboard, copyToClipboard, downloadImage, emailLink, handleClose, imageBlob, open]
  );

  return res;
};

export default ShareModal;
