import React, { useState } from "react";
import { Button, Grid, TextField, Link, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import * as yup from "yup";
import PasswordComponent, { passwordValidation } from "../components/PasswordComponent";
import AppBackService from "../services/appback";
import { colors } from "../styles/colors";
import { commonStyles } from "../styles/commonStyles";

const styles = {
  formStyle: { width: "90%", marginLeft: "auto", marginRight: "auto" },
  textFieldColors: { outline: colors.secondaryGreen, background: colors.white },
  errorTextStyle: { color: colors.errorTextColor },
  nameTextFieldStyle: {
    width: "96%",
    fieldset: { borderColor: colors.secondaryGreen },
    backgroundColor: colors.white,
  },
  infoTextFieldStyle: {
    width: "88%",
    marginBottom: "20px!important",
    fieldset: { borderColor: colors.secondaryGreen },
    backgroundColor: colors.white,
  },
  buttonStyle: {
    width: "88%",
    height: "50px",
    backgroundColor: colors.secondaryGreen,
    marginBottom: "10px",
    "&:hover": { backgroundColor: colors.thickGreen },
  },
  gridContainerStyle: {
    textAlign: "center",
    marginBottom: "10px",
    marginTop: "15px",
  },
  linkStyle: { color: colors.secondaryGreen, marginTop: "1em" },
  belowSignupStyle: {
    display: "inherit",
    margin: "0 auto",
  },
  nameGridStyle: {
    marginTop: "25px",
    marginLeft: "-8px",
    flexFlow: "row wrap",
    paddingRight: "5%",
    paddingLeft: "5%",
    paddingBottom: 2,
  },

  formDivStyle: { marginTop: "-10px" },
  nameFieldLabelStyle: { marginLeft: "5px", color: colors.lightBlack, fontSize: "16px", marginBottom: "10px" },
  infoFieldLabelStyle: { marginLeft: "24px", color: colors.lightBlack, fontSize: "16px", marginBottom: "10px" },
};

const validationSchema = yup.object({
  first_name: yup.string("Enter your first name").required("First name is required"),
  last_name: yup.string("Enter your last name").required("Last name is required"),
  email_address: yup
    .string("Enter your email address")
    .email("Please enter valid email")
    .required("Email address is required"),
  password: passwordValidation(),
});

const SignUp = () => {
  const [values, setValues] = React.useState({
    othercountry: false,
    first_name: "",
    last_name: "",
    email_address: "",
    password: "",
    confirm_password: "",
    phone_number: "",
    showPassword: false,
    showConfirmPassword: false,
    showConfirmation: false,
    authCode: "",
    userSub: "",
    error: "",
    error1: "",
    auth_code_error: "",
    first_name_required_error: "",
    last_name_required_error: "",
    email_required_error: "",
    email_valid_error: "",
    password_required_error: "",
    confirm_password_required_error: "",
  });
  const [zAuthCode, setzAuthCode] = useState(new URLSearchParams(window.location.search).get("code"));

  let location = useLocation();
  let loginUrlWithQueryParams = "/login" + location.search;

  const handleSubmit = (model) => {
    setValues({ ...values });
    const { email_address, password } = model;
    const username = email_address.toLowerCase();
    try {
      Auth.signUp({
        username,
        password,
        attributes: {
          email: email_address.toLowerCase(),
          given_name: model.first_name,
          family_name: model.last_name,
        },
      })
        .then((data) => {
          setValues({
            showConfirmation: true,
            email_address: username,
            password,
            first_name: values.first_name,
            last_name: values.last_name,
            userSub: data.userSub,
          });
          console.log("calling addZoomAuthCode with userSub: " + data.userSub + " and zAuthCode: " + zAuthCode);
          AppBackService.upsertZoomAuthCode(data.userSub, zAuthCode);
        })
        .catch((error) => {
          if (error.message === "An account with the given email already exists.") {
            setValues({ ...values, error: error.message });
          } else setValues({ ...values, error: error.message });
        });
    } catch (error) {
      setValues({ ...values, error: error.message });
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email_address: "",
      phone_number: "",
      password: "",
    },
    validationSchema,
    onSubmit: (model) => {
      handleSubmit(model);
    },
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePassword = (prop) => (event) => {
    formik.values.password = event.target.value;
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  return (
    <div>
      {!values.showConfirmation && (
        <div>
          <p style={commonStyles.signupLoginHeader}>Create Account</p>
          <p style={styles.errorTextStyle}>{values.error}</p>
          <div style={styles.formDivStyle}>
            <form onSubmit={formik.handleSubmit} style={styles.formStyle}>
              <Grid container spacing={1} columns={2} rowSpacing="0px" sx={styles.nameGridStyle}>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                  <Typography sx={styles.nameFieldLabelStyle} align="left">
                    First Name
                  </Typography>
                  <TextField
                    variant="outlined"
                    sx={styles.nameTextFieldStyle}
                    inputProps={{ style: { height: "18px" } }}
                    id="first_name"
                    name="first_name"
                    placeholder="First Name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    helperText={formik.touched.first_name && formik.errors.first_name}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                  <Typography sx={styles.nameFieldLabelStyle} align="left">
                    Last Name
                  </Typography>
                  <TextField
                    variant="outlined"
                    sx={styles.nameTextFieldStyle}
                    inputProps={{ style: { height: "18px" } }}
                    id="last_name"
                    name="last_name"
                    placeholder="Last Name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    helperText={formik.touched.last_name && formik.errors.last_name}
                  />
                </Grid>
              </Grid>
              <Typography sx={styles.infoFieldLabelStyle} align="left">
                Email Address
              </Typography>
              <TextField
                variant="outlined"
                sx={styles.infoTextFieldStyle}
                id="email_address"
                name="email_address"
                placeholder="Email Address"
                value={formik.values.email_address}
                onChange={formik.handleChange}
                error={formik.touched.email_address && Boolean(formik.errors.email_address)}
                helperText={formik.touched.email_address && formik.errors.email_address}
              />
              <Typography sx={styles.infoFieldLabelStyle} align="left">
                Password
              </Typography>
              <PasswordComponent
                values={values}
                placeholder="Password"
                name="password"
                type={values.showPassword ? "text" : "password"}
                value={formik.values.password}
                onChange={handleChangePassword("password")}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                style={styles.infoTextFieldStyle}
                label=""
              />
              <p>
                By signing up, you agree to the&nbsp;
                <Link sx={styles.linkStyle} href="https://vtapestry.com/tos">
                  Terms and Conditions
                </Link>
                &nbsp;and&nbsp;
                <Link sx={styles.linkStyle} href="https://vtapestry.com/privacy">
                  Privacy Policy
                </Link>
                .
              </p>
              <Button type="submit" variant="contained" sx={styles.buttonStyle} id="continue_button">
                CONTINUE
              </Button>
              <br />
            </form>
          </div>
          <Grid container spacing={1} justifyContent="center" sx={styles.gridContainerStyle}>
            <div style={styles.belowSignupStyle}>
              <Grid container spacing={0}>
                <p>
                  Already have an account? &nbsp;
                  <Link sx={styles.linkStyle} href={loginUrlWithQueryParams}>
                    Login
                  </Link>
                </p>
              </Grid>
            </div>
          </Grid>
        </div>
      )}
      {values.showConfirmation && (
        <div>
          <p
            align="center"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingBottom: "24px",
            }}>
            We have sent an email verification link to you at {values.email_address}
            .
            <br />
            <br />
            Please close this tab and click on the link in your email to verify the email address.
          </p>
        </div>
      )}
    </div>
  );
};

export default SignUp;
