import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, Button } from "@mui/material";
import PropTypes from "prop-types";
import { commonStyles } from "../styles/commonStyles";

const ConfirmDialogComponent = React.forwardRef(
  ({ dialogOpen, setDialogOpen, children, confirmToBack, menuName }, ref) => (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-describedby="alert-dialog-description"
        ref={ref}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
        </DialogContent>
        <DialogActions id="MuiDialogActions-root">
          <Button sx={commonStyles.dialogBtnStyle} onClick={() => confirmToBack(menuName)} id="close_button">
            Ok
          </Button>
          <Button sx={commonStyles.dialogBtnStyle} onClick={() => setDialogOpen(false)} id="close_button">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
);

ConfirmDialogComponent.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  confirmToBack: PropTypes.func.isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
  menuName: PropTypes.string.isRequired,
};
export default ConfirmDialogComponent;
