import React, { useCallback, useMemo, useState } from "react";
import { Snackbar, Alert, SnackbarContent, Button } from "@mui/material";
import { colors } from "../styles/colors";

const styles = {
  snackbarStyle: { width: "100%", marginBottom: "30px", marginTop: "56px" },
  snackbarContentStyle: { background: colors.white, color: "black", width: "320px", fontWeight: 600 },
};

/**
 * Represents a user alert.
 *
 * Leverages Material UI Components Alert and Snackbar.
 *
 * @constructor
 * @param {string} message -  Message to display in alert
 * @param {function} action - html representing a CTA on the alert
 * @param {string} severity - Possible values: "error", "warning", "info", "success"
 * @param {boolean} open - Boolean to control whether the alert is open or closed
 * @param {function} setOpen - Function to set the open state of the alert
 */
const AlertNotification = ({ message, severity, open, setOpen, action }) => {
  const handleClose = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setOpen(false);
    },
    [setOpen]
  );

  const res = useMemo(
    () => (
      <div>
        {severity ? (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert onClose={handleClose} severity={severity} action={action} sx={styles.snackbarStyle}>
              {message}
            </Alert>
          </Snackbar>
        ) : (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            sx={styles.snackbarStyle}>
            <SnackbarContent message={message} action={action} sx={styles.snackbarContentStyle} />
          </Snackbar>
        )}
      </div>
    ),
    [action, handleClose, message, open, severity]
  );

  return res;
};

export function useSnackbar() {
  const [snackbar, setSnackbar] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [clickSnackbar, setClickSnackbar] = useState();
  const [cta, setCta] = useState();

  const showSnackbar = useCallback((message, snackbarSeverity, ctaText, onClick) => {
    setSnackbar(message);
    setSeverity(snackbarSeverity);
    setClickSnackbar(() => onClick);
    setCta(ctaText);
  }, []);

  const ctaEl = useMemo(
    () =>
      clickSnackbar && cta ? (
        <Button size="small" onClick={() => clickSnackbar()}>
          {cta}
        </Button>
      ) : null,
    [clickSnackbar, cta]
  );

  const snackbarEl = useMemo(
    () => (
      <AlertNotification
        open={!!snackbar}
        setOpen={setSnackbar}
        message={snackbar}
        severity={severity}
        action={ctaEl}
      />
    ),
    [ctaEl, severity, snackbar]
  );

  return { snackbarEl, showSnackbar };
}

export default AlertNotification;
