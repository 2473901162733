import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Button, Container, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import PasswordComponent, { passwordValidation } from "../components/PasswordComponent";
import { colors } from "../styles/colors";

const validationSchema = yup.object({
  new_password: passwordValidation(),
  confirm_password: yup
    .string("Enter your confirm password")
    .required("Confirm Password is required")
    .oneOf([yup.ref("new_password"), null], "Passwords don’t match"),
});

const styles = {
  resetText: {
    textDecoration: "underline",
    color: "blue",
  },
};

const EmailVerify = (props) => {
  const location = useLocation();
  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleChangePassword(values);
    },
  });

  const [values, setValues] = useState({
    resetConfirmed: false,
    emailConfirmed: false,
    alreadyConfirmed: false,
  });
  const search = location.search;
  const params = new URLSearchParams(search);
  const email = params.get("email");
  const verificationCode = params.get("verification_code");
  const type = params.get("type").toLocaleUpperCase();
  const isPasswordReset = type === "PASSWORD_RESET";

  useEffect(() => {
    handleVerify();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePassword = (model) => {
    Auth.forgotPasswordSubmit(email, verificationCode, model.new_password)
      .then(() => {
        setValues({ ...values, resetConfirmed: true });
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);
      })
      .catch((error) => {
        setValues({ ...values, error: error.message });
      });
  };

  const handleVerify = async () => {
    if (!isPasswordReset) {
      Auth.confirmSignUp(email, verificationCode)
        .then(() => {
          setValues({ ...values, emailConfirmed: true });
        })
        .catch((e) => {
          let message = "Your account not verified. Please check email address and verification code.";
          if (e.message.includes("Current status is CONFIRMED")) {
            message = "";
            setValues({ ...values, alreadyConfirmed: true });
          } else if (e.code === "LimitExceededException") {
            message = e.message;
          }
          if (message !== "") {
            setValues({ ...values, emailConfirmed: false });
            setValues({
              ...values,
              error: message,
            });
          }
        });
    }
  };
  const handleLogin = () => {
    window.location.href = "/";
  };
  const handlePasswordChange = (prop) => (event) => {
    formik.values.new_password = event.target.value;
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };
  const handleConfirmPasswordChange = (prop) => (event) => {
    formik.values.confirm_password = event.target.value;
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };
  return (
    <div>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={1} sm={2} md={2} lg={3}>
            {" "}
          </Grid>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            lg={6}
            style={{
              marginTop: "5%",
              borderRadius: "10px",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}>
            {isPasswordReset && (
              <div>
                <br />
                <p style={{ color: colors.errorTextColor }}>{values.error}</p>
                <form onSubmit={formik.handleSubmit}>
                  <PasswordComponent
                    values={values}
                    label="New Password"
                    name="new_password"
                    type={values.showPassword ? "text" : "password"}
                    value={formik.values.new_password}
                    onChange={handlePasswordChange("new_password")}
                    error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                    helperText={formik.touched.new_password && formik.errors.new_password}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    formik={formik}
                    width="80%"
                  />
                  <PasswordComponent
                    values={values}
                    label="Confirm Password"
                    name="confirm_password"
                    type={values.showConfirmPassword ? "text" : "password"}
                    value={formik.values.confirm_password}
                    onChange={handleConfirmPasswordChange("confirm_password")}
                    error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                    helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    width="80%"
                  />
                  <Button
                    type="sumbit"
                    variant="contained"
                    style={{
                      width: "80%",
                      height: "50px",
                      backgroundColor: colors.changePwdBtn,
                      color: colors.white,
                    }}
                    id="change_password_button">
                    Change Password
                  </Button>
                </form>
              </div>
            )}
            {!isPasswordReset && (
              <div>
                {!values.emailConfirmed && <p style={{ color: colors.errorTextColor }}>{values.error}</p>}
                {values.emailConfirmed && (
                  <p align="center">
                    {" "}
                    Your account is verified. You can{" "}
                    <span tabindex="0" style={styles.resetText} onClick={handleLogin}>
                      click here to login
                    </span>{" "}
                  </p>
                )}
                {values.alreadyConfirmed && (
                  <p align="center">
                    {" "}
                    Your account has already been verified.{" "}
                    <span tabindex="0" style={styles.resetText} onClick={handleLogin}>
                      To continue login.
                    </span>{" "}
                  </p>
                )}
              </div>
            )}
            {values.resetConfirmed && (
              <div>
                <p align="center">
                  {" "}
                  Your password has been successfully changed. You can{" "}
                  <span tabindex="0" style={styles.resetText} onClick={handleLogin}>
                    click here to login
                  </span>{" "}
                </p>
              </div>
            )}
          </Grid>
          <Grid item xs={1} sm={2} md={2} lg={3}>
            {" "}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default EmailVerify;
