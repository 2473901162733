import React, { useState, useCallback, useMemo, useRef, useEffect } from "react";
import { Button, TextField, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import { colors } from "../styles/colors";

const styles = {
  containerStyle: { marginLeft: "5px", flexGrow: 1 },
  saveButtonStyle: {
    backgroundColor: colors.secondaryGreen,
    marginLeft: "8px",
    marginRight: "8px",
    textAlign: "center",
    width: "78px",
    height: "34px",
  },
  iconContainerStyle: {
    textAlign: "left",
    marginRight: "5px",
  },
  griItemStyle: { paddingTop: "20px" },
  editIconStyle: {
    margin: "15px 5px 15px 6px",
    cursor: "pointer",
    color: colors.secondaryGreen,
  },
  textStyle: {
    textAlign: "left",
    cursor: "text",
    margin: "0",
  },
};

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: colors.white,
    "& fieldset": {
      borderColor: colors.secondaryGreen,
    },
    "&:hover fieldset": {
      borderColor: colors.green,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.secondaryGreen,
    },
  },
});

const InlineEdit = ({ value, setValue, submit, placeholder, textStyle, enterSubmit }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();

  const startEditing = useCallback(() => {
    setEditing(true);
  }, []);

  const doSubmit = useCallback(() => {
    submit(value);
    setEditing(false);
  }, [submit, value]);

  const handleEnter = useCallback(
    (e) => {
      if (e.key === "Enter" && enterSubmit) {
        e.preventDefault();
        submit(value);
        setEditing(false);
      }
    },
    [enterSubmit, submit, value]
  );

  const updateValue = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current.selectionStart = inputRef.current.selectionEnd = 10000;
      });
    }
  }, [editing]);

  const res = useMemo(
    () => (
      <div className="inline-edit-container" style={styles.containerStyle}>
        <Grid container alignItems="center">
          <Grid item xs="auto" sm="auto" md="auto" lg="auto">
            <div style={styles.iconContainerStyle}>
              <EditIcon style={styles.editIconStyle} onClick={startEditing} />
            </div>
          </Grid>
          <Grid item xs={11} sm={11} md={11} lg={11}>
            {editing ? (
              <Grid container alignItems="center">
                <Grid item xs={9} sm={9} md={10} lg={10}>
                  <CssTextField
                    value={value || ""}
                    multiline
                    fullWidth
                    onKeyDown={handleEnter}
                    onChange={updateValue}
                    placeholder={placeholder}
                    inputRef={inputRef}
                  />
                </Grid>
                <Grid item xs={2} sm={2} md={1} lg={1}>
                  <Button onClick={doSubmit} type="submit" variant="contained" sx={styles.saveButtonStyle}>
                    Ok
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <p onClick={startEditing} style={{ ...styles.textStyle, ...textStyle }}>
                {value || <span className="empty-edit">({placeholder})</span>}
              </p>
            )}
          </Grid>
        </Grid>
      </div>
    ),
    [startEditing, editing, value, handleEnter, updateValue, placeholder, doSubmit, textStyle]
  );

  return res;
};

export default InlineEdit;
