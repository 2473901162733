import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import CancelIcon from "@mui/icons-material/Cancel";
import ShareModal from "../components/ShareModal";
import { colors } from "../styles/colors";
import { commonStyles } from "../styles/commonStyles";

const styles = {
  tapLink: {
    position: "relative",
    display: "inline-block",
    margin: "16px",
    marginTop: 0,
    "& .tap-action": {
      display: "none",
    },
    "&:hover": {
      "& .tap-action": {
        display: "flex",
      },
      opacity: 0.75,
    },
  },
  tapShareContainer: {
    backgroundColor: colors.grey,
    height: "24px",
    width: "24px",
    borderRadius: "22px",
    boxSizing: "border-box",
    border: `2px solid ${colors.white}`,
    position: "absolute",
    top: "8px",
    right: "36px",
    cursor: "pointer",
    zIndex: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.secondaryGreen,
    },
  },
  tapShare: {
    color: colors.white,
    height: "14px",
    marginTop: "-1px",
    display: "block",
  },

  tapImg: {
    height: "140px",
  },
};

const Taps = ({ taps, deleteTap }) => {
  const [shareTap, setShareTap] = useState(null);

  const handleModalClose = useCallback(() => {
    setShareTap(null);
  }, []);

  return (
    <>
      {taps.map((tap) => (
        <Box className="tap-link" key={tap.tapId} sx={styles.tapLink}>
          <Link to={"/tapestry/" + tap.tapId}>
            <img
              className="tap-img"
              src={`https://taps.tapestryai.com/${tap.tapId}_tn.png`}
              style={styles.tapImg}
              alt="tapestry"
            />
          </Link>
          <Box className="tap-action" sx={commonStyles.tapDeleteContainer}>
            <CancelIcon
              fontSize="medium"
              sx={commonStyles.tapDelete}
              onClick={(e) => {
                e.stopPropagation();
                deleteTap(tap.tapId, tap.title);
              }}
            />
          </Box>
          <Box className="tap-action" sx={styles.tapShareContainer}>
            <IosShareIcon
              sx={styles.tapShare}
              onClick={(e) => {
                e.stopPropagation();
                setShareTap({
                  url: `https://taps.tapestryai.com/${tap.tapId}.png`,
                  title: tap.title,
                });
              }}
            />
          </Box>
        </Box>
      ))}
      <ShareModal
        open={!!shareTap}
        handleClose={handleModalClose}
        tapShareUrl={shareTap?.url}
        title={shareTap?.title}
      />
    </>
  );
};
Taps.propTypes = {
  taps: PropTypes.array,
  deleteTap: PropTypes.func,
};

export default Taps;
